class CommonClass {
    baseURL() {
        return 'https://lk.baltcourier.ru/api.php' ; 
        // return 'http://localhost:8000/api.php' ; 
    }

    baseWaybillURL() {
        return "https://lk.baltcourier.ru/waybill_v3.php" ;
        // return 'http://localhost:8000/waybill_v3.php' ; 
    }

    sessionUuid() {
        let ca = document.cookie.split("; ").filter((item => (item.split("=")[0]==='session'))) ;
        if (ca.length===0) {
            return "" ;
        }
        return ca[0].split("=")[1] ; 
    } 

    dadataAPIKey() {
        return "75d0e8ed96aa34b2f54c217923b9f1a813d9ccbc" ;
    }

    money(num) {
        let n = Number(num) ;
        if (isNaN(n)) {
            return 0 ;
        } ;
        return Math.round(n*100)/100 ;
    }
}



const common = new CommonClass() ;

export default common;