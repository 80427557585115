import React, {Component} from 'react';
import Paper from '@material-ui/core/Paper';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import {Button} from '@material-ui/core';
import common from './Common.js' ;

class Message extends Component {

    constructor(props) {
        super(props);
        this.state = {
            message: "",
            showDialog: false,
            DialogMessage: "",
            GotoMain: false 
        } ;
        // binding methods
        // this.<name> = this.<name>.bind(this) ;
        this.onchangeMessage = this.onchangeMessage.bind(this) ;
        this.send = this.send.bind(this) ;
        this.continue = this.continue.bind(this) ;
    }  

    onchangeMessage(event) {
        let value = event.target.value ;
        this.setState({
            message: value   
        })   
    }

    send() {
        let session = common.sessionUuid() ; 
        let baseURL = common.baseURL() ;
        let xhr = new XMLHttpRequest();
        let url = 
            baseURL + "?func=sendMessage" + 
            "&session=" + session + 
            "&message=" + this.state.message ;
        xhr.open('GET', url, true);
        xhr.send() ;
        xhr.onreadystatechange = () => { 
            if (xhr.readyState !== 4) return;
            if (xhr.status === 200) {
                let r = JSON.parse(xhr.responseText) ;               
                if (r.success) {
                    this.setState({
                        showDialog: true,
                        DialogMessage: "Ваше обращение отправлено, Наш сотрудник обязательно свяжется с Вами, ожидайте.",
                        GotoMain: true 
                    }) ;                                                                   
                }else {
                    this.setState({
                        showDialog: true,
                        DialogMessage: "Ошибка. Попробуйте еще раз позже",
                        GotoMain: false 
                    }) ;
                } 
            }        
        } ;   
    }

    continue() {
        let gotoMain = this.state.GotoMain ;
        this.setState({
            showDialog: false,
            DialogMessage: "",
            GotoMain: false 
        }) ;
        if (gotoMain) {
            this.props.history.push('/') ;
        } 
    }    

    render() {
        return (
            <div style={{height: "calc(100vh - 150px)", overflowY: "auto"}}>
                {!this.state.showDialog && 
                    <>
                    <Paper style={{marginBottom: "20px", padding: "20px"}}>
                        <div style={{fontWeight: "bold", fontSize: "120%", color: "#228ec6", marginTop: "20px", marginBottom: "20px"}} >
                            Обращение
                        </div>
                        
                        <div>
                            <InputLabel htmlFor="message">Текст обращения</InputLabel>
                            <Input
                                id="message"
                                style={{width: "100%"}}
                                type='text'
                                value={this.state.message}
                                onChange={this.onchangeMessage}
                                aria-describedby="message-helper-text"
                                multiline
                                rowsMax="30"
                                rows="10"
                            />
                            <FormHelperText id="message-helper-text">
                                Введите текст обращения в компанию, если обращение по заказу, не забудьте указать номер заказа.
                            </FormHelperText>
                        </div>
                    </Paper>                
                    <Paper style={{padding: "20px", lineHeight: "1.7", minWidth: "400px"}}>    
                        <Button
                            style={{color:"#ffffff", backgroundColor: "#228ec6", width: "220px", paddingTop: "14px", paddingBottom: "14px"}}
                            onClick={this.send}
                        >
                            ОТПРАВИТЬ
                        </Button>
                    </Paper>
                    </>
                } 
                {this.state.showDialog &&
                    <Paper style={{marginBottom: "20px", padding: "20px"}}>
                        <b>{this.state.DialogMessage}</b>
                        <br/>
                        <br/>
                        <Button
                            style={{color:"#ffffff", backgroundColor: "#228ec6", width: "220px", paddingTop: "14px", paddingBottom: "14px"}}
                            onClick={this.continue}
                        >
                            ПРОДОЛЖИТЬ
                        </Button>
                    </Paper>
                }
            </div>
        )
    }

}

export default Message;