import React, {Component} from 'react';
import Paper from '@material-ui/core/Paper';
import common from './Common.js' ;
import Orders from './Orders.js' ;
import { Link } from 'react-router-dom';
import {Button} from '@material-ui/core';
/* import './dadata/dadata.css'; */
import MyReactDadata from './dadata/dadata.js' ;
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import FormHelperText from '@material-ui/core/FormHelperText';

class Home extends Component {

    constructor(props) {
        super(props);
        this.state = {
            clients: [],
            showAddAddress: false,
            AddAddressTo: '',
            address: {
                clientGuid: '',
                address: '',
                addressHaveError: false,
                settlementCode: '',
                settlement: '',
                name: '', 
                telephone: '',
                description: '',
                addInfo: '',
                settlementInRussia: false
            }            
        } ;
        // binding methods
        // this.<name> = this.<name>.bind(this) ;
        this.AddNewAddressToAccount = this.AddNewAddressToAccount.bind(this) ;
        this.onchangeAddress = this.onchangeAddress.bind(this) ;
        this.onchangeName = this.onchangeName.bind(this) ; 
        this.onchangeTelephone = this.onchangeTelephone.bind(this) ;
        this.onchangeAddInfo = this.onchangeAddInfo.bind(this) ;
        this.saveAddress = this.saveAddress.bind(this) ;
    }   

    componentDidMount() {               
        let session = common.sessionUuid() ; 
        let baseURL = common.baseURL() ;       
        let xhr = new XMLHttpRequest();
        let url = baseURL + "?func=GetAccounts&session=" + session ;
        xhr.open('GET', url, true);
        xhr.send() ;
        xhr.onreadystatechange = () => { 
            if (xhr.readyState !== 4) return;
            if (xhr.status === 200) {
                let r = JSON.parse(xhr.responseText) ;               
                if (r.success) {
                    this.setState({
                        clients: r.data
                    }) ;    
                }                  
            }else {
                this.setState({
                    clients: []                        
                }) ;
                console.log(xhr.status) ;    
                console.log(xhr.statusText) ;    
            }
        }
    }    
    
    AddNewAddressToAccount(client_guid) {
        let guid = client_guid ;
        return () => {            
            let address = {
                clientGuid: guid,
                address: '',
                addressHaveError: false,
                addressErrorText: '',
                settlementCode: '',
                settlement: '',
                name: '', 
                telephone: '',
                description: '',
                addInfo: '',
                settlementInRussia: false
            }
            this.setState({
                showAddAddress: true,
                AddAddressTo: guid,
                address: address   
            })           
        }   
    }

    __getAddressFromArr(arr) {
        let result = "" ;
        if (arr[0]===arr[2]) {  // Москва и Санкт-Петербург
            arr[2] = null ;    
        }
        for(let i=0; i<arr.length; i++) {
            if (arr[i]===null ||arr[i]==="") {
                continue ;
            }
            if (result !== "") {
                result = result + ", " ;   
            } 
            result = result + arr[i] ; 
        }
        return result ;
    }

    __getCodeFromArr(arr) {
        for(let i=arr.length-1; i>=0; i--) {
            if (arr[i]===null ||arr[i]==="") {
                continue ;
            }
            return arr[i] ;    
        }
        return "" ;
    }

    onchangeAddress(suggestion) {
        let address = this.state.address ;
        address.address = suggestion.value ; 

        address.settlementCode = '' ;
        address.settlement = '' ;
        address.addressHaveError = false ;
        address.addressErrorText = '' ;
        
        let data = suggestion.data ;
        if (data.settlement_kladr_id !== null) {
            address.settlementCode = data.settlement_kladr_id ;            
        }
        address.settlementCode = this.__getCodeFromArr([
            data.region_kladr_id, 
            data.area_kladr_id, 
            data.city_kladr_id, 
            data.settlement_kladr_id
        ]) ;
        address.settlement = this.__getAddressFromArr([
            data.region_with_type, 
            data.area_with_type, 
            data.city_with_type, 
            data.settlement_with_type
        ]) ;        
        if (address.address.length>0 && address.settlement==='') {
            address.addressHaveError = true ;
            address.addressErrorText = 'Не распознан адрес, при вводе адреса используйте подсказки.' ;   
        } ;
        if (address.settlementCode !== '') {
            address.settlementInRussia = true ;
        } ;
        address.description = address.address + ", " + address.telephone + ", " + address.name + ", " + address.addInfo ; 
        this.setState({
            address: address 
        }) ;       
    }

    onchangeName(event) {
        let address = this.state.address ;
        address.name = event.target.value ;        
        address.description = address.address + ", " + address.telephone + ", " + address.name + ", " + address.addInfo ; 
        this.setState({
            address: address
        }) ; 
    }

    onchangeTelephone(event) {
        let address = this.state.address ;
        address.telephone = event.target.value ;        
        address.description = address.address + ", " + address.telephone + ", " + address.name + ", " + address.addInfo ; 
        this.setState({
            address: address
        }) ; 
    }

    onchangeAddInfo(event) {
        let address = this.state.address ;
        address.addInfo = event.target.value ;        
        address.description = address.address + ", " + address.telephone + ", " + address.name + ", " + address.addInfo ; 
        this.setState({
            address: address
        }) ; 
    }

    saveAddress() {
        let session = common.sessionUuid() ; 
        let baseURL = common.baseURL() ; 
        let xhr = new XMLHttpRequest();
        let url = baseURL + "?func=addNewAddress&session=" + session + "&client=" + this.state.AddAddressTo ;
        xhr.open('POST', url, true);
        xhr.setRequestHeader('Content-type', 'application/json');
        let addressJSON = JSON.stringify(this.state.address) ;
        let props = this.props ;
        xhr.send(addressJSON) ;
        xhr.onreadystatechange = () => { 
            if (xhr.readyState !== 4) return;
            if (xhr.status === 200) {
                /* onsole.log(xhr.responseText) ; */
                let r = JSON.parse(xhr.responseText) ;               
                if (r.success) {
                    window.setTimeout(()=>{
                        props.history.push('/') ;
                    }, 200);
                    props.history.push("/empty") ;                                                                    
                } 
            }        
        } ; 
    }

    f(value) {
        return value===""?"____________":value ;
    }

    render() {
        return (
            <div style={{height: "calc(100vh - 150px)", overflowY: "auto"}}>
                <Paper style={{marginBottom: "20px"}}>
                    <div style={{display: "flex", flexWrap: "wrap", justifyContent:"left", alignContent: "stretch", alignItems: "stretch"}}>
                        {                    
                            this.state.clients.map((item) => (
                                <>
                                <div key={item.guid} style={{borderLeft: "3px solid #228ec6", flexGrow:"1", margin: "10px", color: "rgb(32,32,32)", padding: "20px", maxWidth: "800px", lineHeight: "1.7"}}>                            
                                    <div style={{fontWeight: "bold", fontSize: "120%", color: "#228ec6"}}>{item.description}</div>
                                    <div>ИНН: {this.f(item.data.ИНН)}, ОКОНХ: {this.f(item.data.ОКОНХ)}, ОКПО: {this.f(item.data.ОКПО)}</div>
                                    <div>Договор 
                                        {item.have_contract?" заключен №-" + item.contract_number:" не заключен"} 
                                        {item.discount===0?"":", индивидуальная скидка: " + item.discount + "%"} 
                                    </div>
                                    { (!this.state.showAddAddress || (!(this.state.showAddAddress && this.state.AddAddressTo==item.guid))) &&
                                        <>
                                        <Button 
                                            style={{color:"#ffffff", backgroundColor: "#228ec6"}}                                        
                                            onClick={this.AddNewAddressToAccount(item.guid)}
                                        >
                                            Добавить адрес
                                        </Button>
                                        <div style={{fontWeight: "bold", fontSize: "100%", color: "rgba(0,0,0,0.3)"}}>Адреса забора корреспонеции:</div>
                                        {
                                            item.contacts.map((contact) => (
                                                <div key={contact.toString()}>{" - " + contact.address + ", " + contact.telephone + ", " + contact.name}</div>
                                            ))   
                                        } 
                                        </>                                        
                                    }
                                    { this.state.showAddAddress && this.state.AddAddressTo==item.guid &&
                                        <>
                                        <FormControl style={{width: "100%"}} error={this.state.address.addressHaveError}>
                                            <InputLabel className="MuiInputLabel-shrink MuiFormLabel-filled" htmlFor="address">Адрес:</InputLabel>
                                            <MyReactDadata                                                                            
                                                id="address"
                                                token = {common.dadataAPIKey()}
                                                query = {this.state.address.address}
                                                placeholder = "Введите адрес город, улица, дом, кв используйте подсказки"
                                                onChange = {this.onchangeAddress}
                                            />                            
                                            <FormHelperText id="address-helper-text">{this.state.address.addressErrorText}</FormHelperText>
                                        </FormControl>

                                        { /*
                                        <div>Город откуда:{this.state.address.settlement}</div>
                                        */ }  

                                        <FormControl style={{width: "100%"}}>
                                            <InputLabel htmlFor="name">Контактное лицо</InputLabel>
                                            <Input
                                                id="name"
                                                style={{width: "100%"}}
                                                type='text'
                                                value={this.state.address.name}
                                                onChange={this.onchangeName}
                                                aria-describedby="name-helper-text"                              
                                            />
                                            <FormHelperText id="name-helper-text"></FormHelperText>
                                        </FormControl>      
                                        
                                        <FormControl style={{width: "100%"}}>
                                            <InputLabel htmlFor="telephone">Телефон</InputLabel>
                                            <Input
                                                id="telephone"
                                                style={{width: "100%"}}
                                                type='text'
                                                value={this.state.address.telephone}
                                                onChange={this.onchangeTelephone}
                                                aria-describedby="telephone-helper-text"                              
                                            />
                                            <FormHelperText id="telephone-helper-text"></FormHelperText>
                                        </FormControl> 

                                        <FormControl style={{width: "100%"}}>
                                            <InputLabel htmlFor="addInfo">Доп. информация</InputLabel>
                                            <Input
                                                id="addInfo"
                                                style={{width: "100%"}}
                                                type='text'
                                                value={this.state.address.addInfo}
                                                onChange={this.onchangeAddInfo}
                                                aria-describedby="addInfo-helper-text"                              
                                            />
                                            <FormHelperText id="addInfo-helper-text"></FormHelperText>
                                        </FormControl> 
                                        
                                        { /*
                                        <p>
                                            {this.state.address.description}
                                        </p>
                                        */ }
                                        
                                        <Button 
                                            style={{color:"#ffffff", backgroundColor: "#228ec6"}}                                        
                                            onClick={this.saveAddress}
                                        >
                                            Сохранить
                                        </Button>
                                        </>
                                    }
                                </div>                                
                                </>                        
                            ))                    
                        }                              
                    </div>
                </Paper>

                <Paper style={{marginBottom: "20px", padding: "20px"}}>              
                    <b>Отслеживание заказа по номеру накладной <a style={{color: "#228ec6"}} target="__blank" href="http://baltcourier.ru/track/">перейти ...</a></b>
                </Paper>


                <Orders forHome="true"/>

                <Paper style={{marginBottom: "20px", padding: "20px"}}>
                    <b>Документация по API  <Link style={{color: "#228ec6"}} to={'/apidocs'}>перейти ...</Link></b>
                </Paper>
                
            </div>            
        )
    }

}

export default Home;