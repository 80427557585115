import React, {Component} from 'react';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import NativeSelect from '@material-ui/core/NativeSelect';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import {Button} from '@material-ui/core';
/* import {ReactDadata} from 'react-dadata'; */
import './dadata/dadata.css';
import MyReactDadata from './dadata/dadata.js' ;
import common from './Common.js' ;
import creditCard from './credit-card.png' ;

const __newOrder = {
    add_1_kg: "", add_details: "", amount: "", amount_by_price: "",
    base_price: 0,
    cargo_height: "", cargo_length: "", cargo_type: "", cargo_volumetric_weight: "", cargo_weight: "", cargo_width: "", cash: "", client_discont: "", cod: false, 
    cod_amount: "", create_dt: "",
    date: "", delivery_time: "",
    guid: "",
    is_paid: "",
    note: "", number: "",
    office: "", orders_amount: "", orders_amount_discount: "",
    paid_type: "", pay_sender: "", payer_guid: "",
    quantity_discount: "", quantity_discount_possible: "", quantity_shipments: "", quantity_shipments_need: "",
    receiver: "", receiver_address: "", receiver_fio: "", receiver_fio_position: "", receiver_guid: "", receiver_settlement: "", receiver_settlement_code: "", 
    receiver_settlement_in_russia: "", receiver_telephone: "", receiving_dt: "",
    scheduled_delivery_date: "", sender_address: "", sender_contract_number: "", sender_email: "", sender_guid: "", sender_telephone: "", settlement_discount: "", status: "",
    tariff_detail: "",
    update_dt: "", urgent_delivery: "",
    waybill_number: "",
    sender_number: "",
    current_line: 1,
    lines: [
        {
            number: 1,
            description: "",
            cargo_type: "Письмо",
            cargo_length: 0,
            cargo_width: 0,
            cargo_height: 0,
            cargo_volumetric_weight: 0,
            cargo_weight: 0,
            cod: false,
            cod_amount: 0,
            insurable_value: 0
        }
    ],
    amount_of_places : 0, 
    cargo_insuarence:false, 
    insurable_value : 0, 
    insurance_tariff : 0, 
    premium_to_insurer : 0, 
    delivery_option:"", 
    delivery_tariff:"", 
    payment_option:"",
    payer_option:"", 
    add_1_kg_50 : 0, 
    add_1_kg_100 : 0, 
    add_1_kg_200 : 0, 
    add_1_kg_300 : 0, 
    add_1_kg_400 : 0, 
    add_1_kg_500 : 0, 
    add_1_kg_600 : 0, 
    add_1_kg_700 : 0,
    add_1_kg_800 : 0, 
    add_1_kg_900 : 0, 
    add_1_kg_1000 : 0, 
    office_receiver : "", 
    add_1_kg_10 : 0, 
    add_1_kg_5 : 0
} ;

function getNewOrder() {
    let newOrder = {...__newOrder} ;
    newOrder.lines = [] ;
    newOrder.lines[0] = {...__newOrder.lines[0]} ;
    return newOrder ;
}

class Order extends Component {

    constructor(props) {
        super(props);
        this.state = {
            guidFromQuery: props.match.params.guid,
            guid: "",
            contacts: [],            
            addresses: [],
            order: getNewOrder(),
            tariff: "",
            tariffList: []
        }
        // binding methods
        // this.<name> = this.<name>.bind(this) ;
        this.onchangeSenderNumber = this.onchangeSenderNumber.bind(this) ;
        this.onchangeSenderSelect = this.onchangeSenderSelect.bind(this) ;
        this.onchangeSenderAddress = this.onchangeSenderAddress.bind(this) ;
        this.__setAddresses = this.__setAddresses.bind(this) ;
        this.__setContacts = this.__setContacts.bind(this) ;
        this.__setOrderFromAddress = this.__setOrderFromAddress.bind(this) ;
        this.onchangeReceiver = this.onchangeReceiver.bind(this) ;
        this.onchangeReceiverAddress = this.onchangeReceiverAddress.bind(this) ;
        this.onchangeReceiverTelephone = this.onchangeReceiverTelephone.bind(this);
        this.onchangeReceiverFIO = this.onchangeReceiverFIO.bind(this);
        this.onchangeReceiverFIOPosition = this.onchangeReceiverFIOPosition.bind(this);        
        this.__getCodeFromArr = this.__getCodeFromArr.bind(this);
        this.__getAddressFromArr = this.__getAddressFromArr.bind(this);
        this.__getSettlementFromCode = this.__getSettlementFromCode.bind(this); 
        this.onchangeAddDetails = this.onchangeAddDetails.bind(this);
        this.onchangePaidType = this.onchangePaidType.bind(this);        
        this.onchangePayer = this.onchangePayer.bind(this);
        this.__inRange = this.__inRange.bind(this);
        this.__getAddPrice = this.__getAddPrice.bind(this);
        this.calcOrderAmount = this.calcOrderAmount.bind(this);
        this.onlinePay = this.onlinePay.bind(this);
        this.addCargoItem = this.addCargoItem.bind(this);
        this.setCurrentLine = this.setCurrentLine.bind(this);
        this.setValueAtCurrentLine = this.setValueAtCurrentLine.bind(this);
        this.pressOkAtCargoList = this.pressOkAtCargoList.bind(this);
        this.pressDeleteAtCargoList = this.pressDeleteAtCargoList.bind(this);
        this.__getClientDiscountBySettlent = this.__getClientDiscountBySettlent.bind(this);
        this.LoadTariff = this.LoadTariff.bind(this);
        this.onchangeTariff = this.onchangeTariff.bind(this);
        this.save = this.save.bind(this);
        this.__getWaybillHRef = this.__getWaybillHRef.bind(this);
        this.print = this.print.bind(this);
    }

    onchangeSenderNumber(event) {
        let order = this.state.order ;
        let value = event.target.value ; 
        order.sender_number = value ;
        this.setState({
            order: order
        }) ;
    } 
    
    onchangeSenderSelect(event) {
        let order = this.state.order ;
        order.sender_guid = event.target.value ; 
        order.payer_guid = order.sender_guid ;           
        order.sender_address = "" ; 
        order.sender_telephone = "" ;  
        order.sender_email = "" ; 
        order.sender_contract_number = "" ;
        for(let i=0; i< this.state.contacts.length; i++) {
            if (this.state.contacts[i].guid === order.sender_guid) {
                let contact = this.state.contacts[i] ;
                order.sender_email = contact.email ;
                if (contact.have_contract === "1") {
                    order.sender_contract_number = contact.contract_number ;
                } ;                 
            }
        } ;
        this.setState({
            order: order
        }) ;
        this.__setMonthAmount() ;
        this.__setAddresses() ;
        this.__getClientDiscountBySettlent();
    }

    __setMonthAmount() {
        let session = common.sessionUuid() ; 
        let baseURL = common.baseURL() ;   
        let xhr = new XMLHttpRequest();
        let clientGuid = this.state.order.sender_guid ;
        let url = baseURL + "?func=GetClientMonthAmount&session=" + session + "&client=" + clientGuid ;
        xhr.open('GET', url, true);
        xhr.send() ;
        xhr.onreadystatechange = () => { 
            if (xhr.readyState !== 4) return;
            if (xhr.status === 200) {
                let r = JSON.parse(xhr.responseText) ;               
                if (r.success) {
                    let order = this.state.order ;
                    order.orders_amount = r.data.monthAmount ;
                    this.setState({
                        order: order 
                    }) ;                        
                }                  
            } ;
        } ;
    }

    __setOrderFromAddress() {
        
        let order = this.state.order ;        
        let currSender = undefined ;
        for(let i=0;i<this.state.contacts.length;i++) {
            if (order.sender_guid === this.state.contacts[i].guid) {
                currSender =  this.state.contacts[i] ; 
                break ;  
            }
        }
        order.sender_telephone = "" ;
        order.sender_name = "" ;
        order.sender_settlement = "" ;
        order.sender_settlement_code = "" ;
        order.sender_settlement_in_russia = "" ;
        order.sender_add_info = "" ;
        order.office = "" ;
        if (currSender !== undefined) {
            for(let i=0;i<currSender.contacts.length;i++) {
                if (currSender.contacts[i].address === order.sender_address) {
                    order.sender_telephone = currSender.contacts[i].telephone ;
                    order.sender_name = currSender.contacts[i].name ;
                    order.sender_settlement = currSender.contacts[i].settlement ;
                    order.sender_settlement_code = currSender.contacts[i].settlement_code ;
                    order.sender_settlement_in_russia = currSender.contacts[i].settlement_in_russia ;
                    order.sender_add_info = currSender.contacts[i].add_info ;
                    let sender_settlement_code = order.sender_settlement_code ; 
                    let region_code = sender_settlement_code.slice(0,2) ;                  
                    if (region_code == "78") {
                        order.office = "С-Петербург" ;
                    } ;
                    if (region_code == "77") {
                        order.office = "Москва" ;
                    } ;
                    break ;
                }                
            }  
        }
        this.setState({
            order: order
        })
        this.LoadTariff() ;
    }

    onchangeSenderAddress(event) {
        let order = this.state.order ;
        order.sender_address = event.target.value ;        
        this.setState({
            order: order
        }) ;  
        this.__setOrderFromAddress() ;       
    }

    __setAddresses() {
        let sender_guid = this.state.order.sender_guid ;
        let addresses = [] ;
        for(let i=0; i< this.state.contacts.length; i++) {
            let contact = this.state.contacts[i] ;
            if (contact.guid === sender_guid) {
                for(let y=0; y < contact.contacts.length; y++) {
                    addresses.push({
                        address: contact.contacts[y].address,
                        description: contact.contacts[y].description
                    }) ; 
                }   
            }
        } ;
        this.setState({
            addresses: addresses    
        }) ;
    }

    __setContacts() {
        let session = common.sessionUuid() ; 
        let baseURL = common.baseURL() ;   
        let xhr = new XMLHttpRequest();
        let url = baseURL + "?func=GetAccounts&session=" + session ;
        xhr.open('GET', url, true);
        xhr.send() ;
        xhr.onreadystatechange = () => { 
            if (xhr.readyState !== 4) return;
            if (xhr.status === 200) {
                let r = JSON.parse(xhr.responseText) ;               
                if (r.success) {
                    this.setState({
                        contacts: r.data
                    }) ;
                    this.__setAddresses() ; 
                    this.__setOrderFromAddress() ;    
                }                  
            } ;
        } ;
    }

    onchangeReceiver(event) {
        let order = this.state.order ;
        order.receiver = event.target.value ;        
        this.setState({
            order: order
        }) ;      
    }

    __getAddressFromArr(arr) {
        let result = "" ;
        if (arr[0]===arr[2]) {  // Москва и Санкт-Петербург
            arr[2] = null ;    
        }
        for(let i=0; i<arr.length; i++) {
            if (arr[i]===null ||arr[i]==="") {
                continue ;
            }
            if (result !== "") {
                result = result + ", " ;   
            } 
            result = result + arr[i] ; 
        }
        return result ;
    }

    __getCodeFromArr(arr) {
        for(let i=arr.length-1; i>=0; i--) {
            if (arr[i]===null ||arr[i]==="") {
                continue ;
            }
            return arr[i] ;    
        }
        return "" ;
    }

    onchangeReceiverAddress(suggestion) {
        let order = this.state.order ;
        order.receiver_address = suggestion.value ;
        let data = suggestion.data ;
        if (data.settlement_kladr_id !== null) {
            order.receiver_settlement_code = data.settlement_kladr_id ;            
        }
        order.receiver_settlement_code = this.__getCodeFromArr([
            data.region_kladr_id, 
            data.area_kladr_id, 
            data.city_kladr_id, 
            data.settlement_kladr_id
        ]) ;
        order.receiver_settlement = this.__getAddressFromArr([
            data.region_with_type, 
            data.area_with_type, 
            data.city_with_type, 
            data.settlement_with_type
        ]) ;
        if (order.receiver_settlement_code !== '') {
            order.receiver_settlement_in_russia = true ;
        }
        this.setState({
            order: order
        }) ;
                
        this.__getClientDiscountBySettlent();
        this.LoadTariff() ;
    }

    __getSettlementFromCode() {
        let code =  this.state.order.sender_settlement_code ;
        if (code == undefined) {
            code = "" ;
        } ;
        if (code.slice(0,2)==="77") {
            return "77000000000" ;
        }else if (code.slice(0,2)==="78") {
            return "78000000000" ;            
        }else {
            return "" ;
        } 
    }

    onchangeReceiverTelephone(event) {
        let order = this.state.order ;
        order.receiver_telephone = event.target.value ;        
        this.setState({
            order: order
        }) ;      
    }

    onchangeReceiverFIO(event) {
        let order = this.state.order ;
        order.receiver_fio = event.target.value ;        
        this.setState({
            order: order
        }) ;      
    }

    onchangeReceiverFIOPosition(event) {
        let order = this.state.order ;
        order.receiver_fio_position = event.target.value ;        
        this.setState({
            order: order
        }) ;      
    }
    
    onchangeAddDetails(event) {
        let order = this.state.order ;
        let value = event.target.value ;
        order.add_details = value ; 
        this.setState({
            order: order   
        })   
    }

    onchangePaidType(event) {
        let order = this.state.order ;
        let value = event.target.value ;
        order.paid_type = value ; 
        this.setState({
            order: order   
        })
    }   

    onchangePayer(event) {
        let order = this.state.order ;
        let value = event.target.value ;
        order.payer_guid = value==="0" ? order.sender_guid : "" ; 
        this.setState({
            order: order   
        })    
    }
    

    __getClientDiscountBySettlent() {
        let session = common.sessionUuid() ; 
        let baseURL = common.baseURL() ;
        let order = this.state.order ;  
        let xhr = new XMLHttpRequest();
        let url = 
            baseURL + "?func=GetClientDiscountBySettlent" + 
            "&session=" + session + 
            "&client=" + order.sender_guid + 
            "&settlement=" + order.receiver_settlement_code ;
        xhr.open('GET', url, true);
        xhr.send() ;
        xhr.onreadystatechange = () => { 
            if (xhr.readyState !== 4) return;
            if (xhr.status === 200) {
                let r = JSON.parse(xhr.responseText) ;               
                if (r.success) {
                    let order = this.state.order ;
                    order.settlement_discount = Number(r.data.discount) ;
                    this.setState({
                        order: order 
                    }) ;                                                                   
                } 
            }        
        } ;     
    }
    
    calcOrderAmount() {
        
        let order = this.state.order ;
        
        if (order.paid_type == 3) {
            order.payment_option = "Наличные" ;
        } ; 

        if (order.sender_guid == order.payer_guid) {
            order.payer_option = "Отправитель" ;
        }else {
            order.payer_option = "Получатель" ;
        } ;        
        
        order.cargo_weight = 0 ;
        order.cod = false ;
        order.cod_amount = 0 ;
        order.insurable_value = 0 ;
        order.amount_of_places = 0 ;
        
        for(let i=0;i<order.lines.length;i++) {
            order.cargo_weight += Math.max(order.lines[i].cargo_volumetric_weight, order.lines[i].cargo_weight) * order.lines[i].quantity ;
            order.lines[i].cod = Number(order.lines[i].cod_amount) > 0 ; 
            order.cod = order.cod || order.lines[i].cod ;
            order.cod_amount += Number(order.lines[i].cod_amount) * order.lines[i].quantity ;
            order.insurable_value += Number(order.lines[i].insurable_value) * order.lines[i].quantity ; 
            order.amount_of_places += Number(order.lines[i].quantity) ;   
        } ;

        let weight = order.cargo_weight ;
        let weightOver1 = Math.max(weight - order.quantity_kg_in_base_price,0) ;
        weightOver1 = Math.ceil(weightOver1) ;	
        let add_1 = this.__getAddPrice(order,weight) ;
        
        if (weight<=order.quantity_kg_in_base_price && weight>0) {
            order.amount_by_price = common.money(order.base_price) ;
        }else if (weight>order.quantity_kg_in_base_price) {
            order.amount_by_price = common.money(common.money(order.base_price) + weightOver1 * add_1) ;
        }
        
        order.amount_by_price = order.amount_by_price * (1 + order.day_off_markup/100) ;
        
        if (order.insurable_value > 0) {
            order.cargo_insuarence = true ;
            order.insurance_tariff = 0.003 ;
            order.premium_to_insurer = common.money(order.insurable_value / 100 * order.insurance_tariff) ;
        }else {
            order.cargo_insuarence = false ;
            order.insurance_tariff = 0.003 ;
            order.premium_to_insurer = 0 ;
        }

        if (order.cod_amount > 0) {
            order.cod = true ;
            order.paid_type = 3 ;
            order.payer_guid = "" ;
            order.payment_option = "Наличные" ;
            order.payer_option = "Получатель" ; 
        }else {
            order.cod = false ; 
        } 

        let discount = Math.max(order.client_discont, order.orders_amount_discount, order.settlement_discount) ;
        let amount_wo_premium_to_insurer = common.money(order.amount_by_price*(1-discount/100)) ;
        order.amount = amount_wo_premium_to_insurer + order.premium_to_insurer ;
        order.tariff_detail = 
            "Скидка клиента: " + order.client_discont + " %, скидка клиента по населенному пункту: " + order.settlement_discount + " %\n" + 
            "Оборот за месяц: " + order.orders_amount + " руб., скидка за оборот: " + order.orders_amount_discount + " %\n" + 
            "Скидка за одновременные отправления: " + order.quantity_discount + " %\n" + 
            "Сумма по прайсу: " + order.amount_by_price + ", сумма со скидкой: " + amount_wo_premium_to_insurer + ", " + 
            "страхование: " + order.premium_to_insurer  ;

        this.setState({
            order: order
        }) ;
    }

        
    __inRange(from, to, value) {
        return Number(value) >= Number(from) && Number(value) < Number(to) ;    
    }
            
    __getAddPrice(order, weght) {
        let _add_1_kg = order.add_1_kg ;
        let _add_1_kg_5 = order.add_1_kg_5 == 0 ? _add_1_kg : order.add_1_kg_5  ;
        let _add_1_kg_10 = order.add_1_kg_10 == 0 ? _add_1_kg_5 : order.add_1_kg_10  ;
        let _add_1_kg_50 = order.add_1_kg_50 == 0 ? _add_1_kg_10 : order.add_1_kg_50  ;
        let _add_1_kg_100 = order.add_1_kg_100 === 0 ? _add_1_kg_50 : order.add_1_kg_100  ;
        let _add_1_kg_200 = order.add_1_kg_200 === 0 ? _add_1_kg_100 : order.add_1_kg_200  ;
        let _add_1_kg_300 = order.add_1_kg_300 === 0 ? _add_1_kg_200 : order.add_1_kg_300  ;
        let _add_1_kg_400 = order.add_1_kg_400 === 0 ? _add_1_kg_300 : order.add_1_kg_400  ;
        let _add_1_kg_500 = order.add_1_kg_500 == 0 ? _add_1_kg_400 : order.add_1_kg_500  ;
        let _add_1_kg_600 = order.add_1_kg_600 == 0 ? _add_1_kg_500 : order.add_1_kg_600  ;
        let _add_1_kg_700 = order.add_1_kg_700 == 0 ? _add_1_kg_600 : order.add_1_kg_700  ;
        let _add_1_kg_800 = order.add_1_kg_800 == 0 ? _add_1_kg_700 : order.add_1_kg_800  ;
        let _add_1_kg_900 = order.add_1_kg_900 == 0 ? _add_1_kg_800 : order.add_1_kg_900  ;
        let _add_1_kg_1000 = order.add_1_kg_1000 == 0 ? _add_1_kg_900 : order.add_1_kg_1000  ;

        let Price = 0 ;
        if (this.__inRange(1, 5, weght)) {
            Price = _add_1_kg ;	
        } else if (this.__inRange(5, 10, weght)) {
            Price = _add_1_kg_5 ;
        } else if ( this.__inRange(10, 50, weght)) {
            Price = _add_1_kg_10 ;
        } else if ( this.__inRange(50, 100, weght)) {
            Price = _add_1_kg_50 ;
        } else if ( this.__inRange(100, 200, weght)) {
            Price = _add_1_kg_100 ;
        } else if ( this.__inRange(200, 300, weght)) {
            Price = _add_1_kg_200 ;
        } else if ( this.__inRange(300, 400, weght)) {
            Price = _add_1_kg_300 ;
        } else if ( this.__inRange(400, 500, weght)) {
            Price = _add_1_kg_400 ;
        } else if ( this.__inRange(500, 600, weght)) {
            Price = _add_1_kg_500 ;
        } else if ( this.__inRange(600, 700, weght)) {
            Price = _add_1_kg_600 ;
        } else if ( this.__inRange(700, 800, weght)) {
            Price = _add_1_kg_700 ;
        } else if ( this.__inRange(900, 1000, weght)) {
            Price = _add_1_kg_900 ;
        } else if (weght >= 1000) {
            Price = _add_1_kg_1000 ;
        } ;
        return Price ;
        
    }


    onlinePay() {
        window.open("http://baltcourier.ru/pay", '_blank');     
    }

    addCargoItem() {
        let order = this.state.order ;
        order.lines.push({
            "guid": order.guid,
            "id": order.guid + "/0000001",
            "number": order.lines.length + 1,
            "description" : "",
            "cargo_type" : "Письмо",
            "cargo_length" : 0,
            "cargo_width" : 0,
            "cargo_height" : 0,
            "cargo_volumetric_weight" : 0,
            "cargo_weight" : 0,
            "cod" : false,
            "cod_amount" : 0,
            "insurable_value" : 0,
            "quantity" : 1
        }) ;
        this.setState({
            order: order,
            current_line: order.lines.length
        }) ;
    }

    setCurrentLine(n) {
        let line = n ;
        let self = this ;
        return () => {
            self.setState({
                current_line: line    
            }) ;
        } ;
    }

    setValueAtCurrentLine(n, name) {
        let line = n ;
        let fieldName = name ;
        let self = this ;
        return (event) => {
            let order = self.state.order ;
            let value = event.target.value ;
            if (fieldName=="cargo_length"
                ||fieldName=="cargo_width"
                ||fieldName=="cargo_height"
                ||fieldName=="cargo_weight"
                ||fieldName=="cod_amount"
                ||fieldName=="insurable_value") {
                value =  value.replace(/[,]/,".") ;        
                value = value.replace(/[^0-9.]/g,"") ;        
            } ;          
            order.lines[line-1][fieldName] = value ;            
            if (fieldName=="cargo_length"||fieldName=="cargo_width"||fieldName=="cargo_height") {                
                let cargo_length = order.lines[line-1].cargo_length ;
                let cargo_height = order.lines[line-1].cargo_height ;
                let cargo_width = order.lines[line-1].cargo_width ;
                order.lines[line-1].cargo_volumetric_weight =  Math.round(cargo_length * cargo_height * cargo_width / 5000 * 1000) /1000 ;  
            } ;
            order.lines[line-1].cod = order.lines[line-1].cod_amount > 0 ;
            self.setState({
                order: order    
            }) ;
            setTimeout(
                () => { this.calcOrderAmount() ; },
                500
            ) ;
        } ;
    }

    pressOkAtCargoList() {
        this.setState({
            current_line: 0    
        }) ;    
    }

    pressDeleteAtCargoList(n) {
        let line = n ;
        let self = this ;    
        return () => {
            let order = self.state.order ; 
            order.lines.splice(line-1,1) ;
            for(let i=0;i<order.lines.length;i++) {
                order.lines[i].number = i + 1 ;   
            }
            self.setState({
                order: order,
                current_line: 0    
            }) ;
            setTimeout(
                () => { this.calcOrderAmount() ; },
                500
            ) ;
        }
    }

    LoadTariff() {
        let session = common.sessionUuid() ; 
        let baseURL = common.baseURL() ; 
        let xhr = new XMLHttpRequest();
        let url = baseURL + "?func=GetTariffList&session=" + session + "&from=" + this.state.order.sender_settlement_code + "&settlement=" + this.state.order.receiver_settlement_code + "&sender=" + this.state.order.sender_guid   ;
        xhr.open('GET', url, true);
        xhr.setRequestHeader('Content-type', 'application/json');
        xhr.send() ;
        xhr.onreadystatechange = () => { 
            if (xhr.readyState !== 4) return;
            if (xhr.status === 200) {
                let r = JSON.parse(xhr.responseText) ;               
                if (r.success) {
                    let serverTariffList = r.data ;
                    let order = this.state.order ;
                    let tariff = '' ;
                    let tariffList = [] ;
                    
                    for(let i=0; i<serverTariffList.length; i++) {
                        let tariffItem = {} ;
                        for (let key in serverTariffList[i]) {
                            tariffItem[key] = serverTariffList[i][key] ;    
                        }
                        tariffItem.description = "Тариф \"" + tariffItem.delivery_tariff ;
                        if (tariffItem.day_off_markup > 0) {
                            tariffItem.id += "_dom" ;
                            tariffItem.description += " выходной день" ;    
                        } ;
                        tariffItem.description += "\", доставка \"" + tariffItem.delivery_option + "\"" ;

                        let weight = order.cargo_weight ;
                        let weightOver1 = Math.max(weight - tariffItem.quantity_kg_in_base_price,0) ;
                        weightOver1 = Math.ceil(weightOver1) ;	
                        let add_1 = this.__getAddPrice(tariffItem, weight) ; 
                        let amount_by_price = 0 ;

                        if (weight<=order.quantity_kg_in_base_price && weight>0) {
                            amount_by_price = common.money(tariffItem.base_price) ;
                        }else if (weight>tariffItem.quantity_kg_in_base_price) {
                            amount_by_price = common.money(common.money(tariffItem.base_price) + weightOver1 * add_1) ;
                        }
                        
                        amount_by_price = amount_by_price * (1 + tariffItem.day_off_markup/100) ;

                        tariffItem.description += ", стоимость " + amount_by_price + " руб." ;                        
                        tariffItem.description += ", срок доставки " + tariffItem.delivery_time + " день(дней)." ; 
                        
                        tariffList.push(tariffItem) ;
                        
                        if (tariffItem.delivery_tariff == order.delivery_tariff 
                            && tariffItem.delivery_option == order.delivery_option 
                            && tariffItem.code == order.receiver_settlement_code.substring(0,11) 
                            && tariffItem.from_city_code == order.sender_settlement_code.substring(0,11)
                            && tariffItem.day_off_markup == order.day_off_markup) {
                                tariff = tariffItem.id ;
                        }

                        if (tariffItem.day_off_markup == 0) {
                            continue ;
                        }

                        tariffItem = {} ;
                        for (let key in serverTariffList[i]) {
                            tariffItem[key] = serverTariffList[i][key] ;    
                        }
                        tariffItem.description = "Тариф \"" + tariffItem.delivery_tariff ;
                        tariffItem.day_off_markup = 0 ;
                        tariffItem.description += "\", доставка \"" + tariffItem.delivery_option + "\"" ;

                        weight = order.cargo_weight ;
                        weightOver1 = Math.max(weight - tariffItem.quantity_kg_in_base_price,0) ;
                        weightOver1 = Math.ceil(weightOver1) ;	
                        add_1 = this.__getAddPrice(tariffItem, weight) ; 
                        
                        if (weight<=order.quantity_kg_in_base_price && weight>0) {
                            amount_by_price = common.money(tariffItem.base_price) ;
                        }else if (weight>tariffItem.quantity_kg_in_base_price) {
                            amount_by_price = common.money(common.money(tariffItem.base_price) + weightOver1 * add_1) ;
                        }
                        
                        amount_by_price = amount_by_price * (1 + tariffItem.day_off_markup/100) ;

                        tariffItem.description += ", стоимость " + amount_by_price + " руб." ; 
                        tariffItem.description += ", срок доставки " + tariffItem.delivery_time + " день(дней)." ; 

                        tariffList.push(tariffItem) ; 
                        
                        if (tariffItem.delivery_tariff == order.delivery_tariff 
                            && tariffItem.delivery_option == order.delivery_option 
                            && tariffItem.code == order.receiver_settlement_code.substring(0,11) 
                            && tariffItem.from_city_code == order.sender_settlement_code.substring(0,11)
                            && tariffItem.day_off_markup == order.day_off_markup) {
                            tariff = tariffItem.id ;
                        } 
                    } ;                                        
                    this.setState({
                        tariff: tariff,
                        tariffList: tariffList
                    }) ;
                } 
            }        
        }  
    }

    onchangeTariff(event) {
        let value = event.target.value ; 
        let order = this.state.order ;
        for(let i=0; i<this.state.tariffList.length; i++) {
            if (this.state.tariffList[i].id==value) {
                order.base_price = this.state.tariffList[i].base_price ;
                order.add_1_kg = this.state.tariffList[i].add_1_kg ;
                order.add_1_kg_5 = this.state.tariffList[i].add_1_kg_5 ;
                order.add_1_kg_10 = this.state.tariffList[i].add_1_kg_10 ;
                order.add_1_kg_50 = this.state.tariffList[i].add_1_kg_50 ;
                order.add_1_kg_100 = this.state.tariffList[i].add_1_kg_100 ;
                order.add_1_kg_200 = this.state.tariffList[i].add_1_kg_200 ;
                order.add_1_kg_300 = this.state.tariffList[i].add_1_kg_300 ;
                order.add_1_kg_400 = this.state.tariffList[i].add_1_kg_400 ;
                order.add_1_kg_500 = this.state.tariffList[i].add_1_kg_500 ;
                order.add_1_kg_600 = this.state.tariffList[i].add_1_kg_600 ;
                order.add_1_kg_700 = this.state.tariffList[i].add_1_kg_700 ;
                order.add_1_kg_800 = this.state.tariffList[i].add_1_kg_800 ;
                order.add_1_kg_900 = this.state.tariffList[i].add_1_kg_900 ;
                order.add_1_kg_1000 = this.state.tariffList[i].add_1_kg_1000 ;
                order.delivery_tariff = this.state.tariffList[i].delivery_tariff ;
                order.delivery_option = this.state.tariffList[i].delivery_option ;
                order.delivery_time = this.state.tariffList[i].delivery_time ;
                order.quantity_shipments_need = this.state.tariffList[i].discount_from_shipment ;
                order.quantity_discount_possible = this.state.tariffList[i].discount ;
                order.quantity_kg_in_base_price = this.state.tariffList[i].quantity_kg_in_base_price ;
                order.day_off_markup = this.state.tariffList[i].day_off_markup ;
            }
        }

        this.setState({
            tariff: value,
            order: order
        }) ;
        
        setTimeout(              
            () => { this.calcOrderAmount() ; },
            500
        ) ;
        
    }

    save() {
        let session = common.sessionUuid() ; 
        let baseURL = common.baseURL() ; 
        let xhr = new XMLHttpRequest();
        let url = baseURL + "?func=saveOrder&session=" + session  ;
        xhr.open('POST', url, true);
        xhr.setRequestHeader('Content-type', 'application/json');
        let orderJSON = JSON.stringify(this.state.order) ;
        xhr.send(orderJSON) ;
        xhr.onreadystatechange = () => { 
            if (xhr.readyState !== 4) return;
            if (xhr.status === 200) {
                /* сonsole.log(xhr.responseText) ; */
                let r = JSON.parse(xhr.responseText) ;               
                if (r.success) {
                    /* this.props.history.push('/order/'+r.data.guid) ;  */
                    this.props.history.push('/orders') ;                                               
                } 
            }        
        } ;  
    }

    __getWaybillHRef(order) {
        
        let contacts = this.state.contacts ;
        let senders = contacts.filter((item)=>{return item.guid===order.sender_guid}) ;
        let senderDescription = "" ;
        if (senders.length>0) {
            senderDescription = senders[0].description ;    
        } ;  
        let payer = order.sender_guid === order.payer_guid ? "0" : "1" ;
        let cod = order.cod ? "1" : "0" ;     
        
        let result = common.baseWaybillURL() + "?" ;
        result += "number=" + order.waybill_number ;
        result += "&date=" + order.date ;
        result += "&sender=" + senderDescription ;  
        result += "&sender_address=" + order.sender_address ;
        result += "&sender_telephone=" + order.sender_telephone ;
        result += "&receiver=" + order.receiver ;
        result += "&receiver_address=" + order.receiver_address ;
        result += "&receiver_settlement=" + order.receiver_settlement ;
        result += "&receiver_telephone=" + order.receiver_telephone ;
        result += "&urgent_delivery=" + order.urgent_delivery ;
        result += "&payer=" + payer ;
        result += "&amount=" + order.amount ;
        result += "&cod=" + cod ;
        result += "&cod_amount=" + order.cod_amount ;
        result += "&weight=" + order.cargo_weight ;
        result += "&tariff=" + order.delivery_tariff ;
        result += "&incoming_number=" + order.sender_number ;
        result += "&amount_of_places=" + order.amount_of_places ;
        for(let i=0; i < order.lines.length; i++) {
            let number = "" + order.lines[i].number  ;  
            let lineStr = "" ;
            if (order.lines[i].cargo_type == "Письмо") {
                lineStr = "Письмо, вес 1 шт." + order.lines[i].cargo_weight + " кг." ;
            } else {
                let dimention = "" + order.lines[i].cargo_length + "х" + order.lines[i].cargo_width + "х" + order.lines[i].cargo_height + " см." ; 
                lineStr = "Посылка " + dimention + ", вес 1 шт. " + order.lines[i].cargo_weight + " кг." ;
            } ;
            if (order.lines[i].cod) {
                lineStr += ", наложенный платеж: " + order.lines[i].cod_amount + " руб. за 1 шт." ;
            } ;
            result += "&inventory_items_" + number + "=" + lineStr ; 
            result += "&quantity_items_" + number + "=" + order.lines[i].quantity ; 
            result += "&description_items_" + number + "=" + order.lines[i].description ;  
        }       

        return result ;
	
    }

    print() {
        let order = this.state.order ;
        if (order.date==="") {
            let currDate = new Date() ;
            order.date = currDate.toISOString().substr(0,19).replace("T"," ") ;
        }    
        if (order.waybill_number==="") {
            /* let waybillNumber = Math.floor((Math.random()*99999999)).toString(36).toUpperCase() ; */
            let waybillNumber = Math.floor(200000000000 + Math.random()*9999999999) ;
            order.waybill_number = waybillNumber ;
            this.setState({
                order: order
            }) ;
        } ;
        let session = common.sessionUuid() ; 
        let baseURL = common.baseURL() ; 
        let xhr = new XMLHttpRequest();
        let url = baseURL + "?func=saveOrder&session=" + session  ;
        xhr.open('POST', url, true);
        xhr.setRequestHeader('Content-type', 'application/json');
        let orderJSON = JSON.stringify(order) ;
        xhr.send(orderJSON) ;
        xhr.onreadystatechange = () => { 
            if (xhr.readyState !== 4) return;
            if (xhr.status === 200) {   
                let r = JSON.parse(xhr.responseText) ;               
                if (r.success) {                     
                    order.guid = r.data.guid ;                                       
                    this.setState({
                        order: order
                    }) ;   
                    window.open(this.__getWaybillHRef(order), '_blank');
                    this.props.history.push('/empty'); 
                    setTimeout(              
                        () => { this.props.history.push('/order/'+r.data.guid); },
                        500
                    ) ;     
                }
            }
        } ;           
    }
    
    componentDidMount() {
        let session = common.sessionUuid() ; 
        let baseURL = common.baseURL() ;     
        if (this.state.guidFromQuery===""||this.state.guidFromQuery==="new") {
            this.setState({
                guid:"",
                order: getNewOrder()
            }) ; 
            this.__setContacts() ;           
        }else {              
            let xhr = new XMLHttpRequest();
            let url = baseURL + "?func=GetOrder&session=" + session + "&order=" + this.state.guidFromQuery ;
            xhr.open('GET', url, true);
            xhr.send() ;
            xhr.onreadystatechange = () => { 
                if (xhr.readyState !== 4) return;
                if (xhr.status === 200) {
                    let r = JSON.parse(xhr.responseText) ;  
                    let order = r.data ;
                    order.cod = order.cod == 1 ;
                    order.cash = order.cash == 1 ;            
                    order.is_paid = order.is_paid == 1 ;
                    if (r.success) {
                        this.setState({
                            guid: this.state.guidFromQuery,
                            order: order 
                        }) ; 
                        this.__setContacts() ;
                        this.LoadTariff() ;                                             
                    } 
                }        
            } ;            
        }        
    } 

    render() {
        let order = this.state.order ;
        let error = false ;
        
        let company = [] ;
        for(let i=0; i< this.state.contacts.length; i++) {
            company.push({
                guid: this.state.contacts[i].guid,
                description: this.state.contacts[i].description
            }) ;            
        } ;
        let addresses = this.state.addresses ;
        let payer = order.sender_guid === order.payer_guid ? "0" : "1" ;
                    
        let disabled = order.status!=="Создан" && order.status!=="" ;
        let disabled_pp = disabled || order.cod ;
        let receiving_dt = order.receiving_dt.length >= 10 ? order.receiving_dt.slice(0,10):"" ;
  
        

        return (
            <>
                
                <div style={{height: "auto"}}>                
                
                <Paper style={{margin: "10px", padding: "20px", lineHeight: "1.7", minWidth: "400px"}}>
                    <div style={{fontWeight: "bold", fontSize: "120%", color: "#228ec6"}}>Заказ № {order.number} от {order.date}</div> 
                    <div>
                        Текущий статус: &nbsp; <b>{order.status}</b>, &nbsp;
                        Номер накладной: &nbsp; <b>{order.waybill_number}</b>, &nbsp;              
                        Статус оплаты: &nbsp;
                        { order.is_paid &&
                            <b>Оплачено</b>
                        }
                        { !order.is_paid &&
                            <b>Не оплачено</b>
                        }       
                    </div>
                    { order.status == "Доставлен" &&     
                        <div>Получено: <b>{order.receiver_fio_position} {order.receiver_fio} {receiving_dt}</b></div>                     
                    }                    
                </Paper>

                <div style={{display: "flex", flexWrap: "wrap", alignItem: "stretch", alignContent: "stretch" }}>

                    <Paper style={{margin: "10px", padding: "20px", lineHeight: "1.7", minWidth: "400px", flexGrow: "1"}}>
                    <div style={{fontWeight: "normal", fontSize: "100%", color: "#228ec6", marginBottom: "10px"}}>Отправитель</div> 

                    <FormControl disabled={disabled} style={{width:"100%"}}>
                        <InputLabel htmlFor="senderSelect" >Организация</InputLabel>
                        <Select
                            style={{width:"100%"}}
                            onChange={this.onchangeSenderSelect}
                            value={order.sender_guid}
                            name="senderSelect"
                            inputProps={{
                                id: 'senderSelect',
                            }}
                        > 
                        { order.sender_guid === "" && 
                            <MenuItem  key="empty" value="">{'\u00A0'}</MenuItem >                        
                        }                                               
                        {   
                            company.map((item) => {
                                return (
                                    <MenuItem key={item.guid} value={item.guid}>{item.description}</MenuItem>
                                )
                            })

                        }                                                
                        </Select>
                        <FormHelperText></FormHelperText>
                    </FormControl>
                     
                    
                    <FormControl disabled={disabled} style={{width:"100%"}}>
                        <InputLabel htmlFor="senderAddress">Адрес</InputLabel>
                        <Select
                            style={{width:"100%"}}
                            onChange={this.onchangeSenderAddress}
                            value={order.sender_address}
                            name="senderAddress"
                            inputProps={{
                                id: 'senderAddress',
                            }}
                        >                        
                        {  order.address === "" && 
                            <MenuItem  key="empty" value="">{'\u00A0'}</MenuItem >                        
                        }
                        {   
                            addresses.map((item) => {
                                return (
                                    <MenuItem  key={item.address} value={item.address}>{item.description}</MenuItem>
                                )
                            })

                        }                                                
                        </Select>
                        <FormHelperText></FormHelperText>
                    </FormControl> 


                    <div>
                        Контактное лицо: <b>{order.sender_name}</b>
                    </div>                    
                    
                    <div>
                        Электронная почта: <b>{order.sender_email}</b>
                    </div>   

                    <div>
                        Телефон: <b>{order.sender_telephone}</b>
                    </div>  

                    <div>
                        Доп. информация: <b>{order.sender_add_info}</b>
                    </div>                       

                    <div>
                        Договор: <b>{order.sender_contract_number}</b>
                    </div>  

                    <div style={{marginTop: "20px"}}>
                        <FormControl disabled={disabled} style={{width: "250px"}}>
                            <InputLabel htmlFor="sender_number">Номер заказ отправителя</InputLabel>
                            <Input
                                id="sender_number"
                                style={{width: "100%"}}
                                type='text'
                                value={this.state.order.sender_number}
                                onChange={this.onchangeSenderNumber}
                                aria-describedby="sender_number-helper-text"                              
                            />
                            <FormHelperText id="sender_number-helper-text"></FormHelperText>
                        </FormControl>
                    </div>                     

                </Paper>     

                    <Paper style={{margin: "10px", padding: "20px", lineHeight: "1.7", minWidth: "400px", flexGrow: "1"}}>
                    <div style={{fontWeight: "normal", fontSize: "100%", color: "#228ec6", marginBottom: "10px"}}>Получатель</div> 

                    <FormControl disabled={disabled} style={{width: "100%"}} error={error}>
                        <InputLabel htmlFor="receiver">Получатель</InputLabel>
                        <Input
                            id="receiver"
                            style={{width: "100%"}}
                            type='text'
                            value={order.receiver}
                            onChange={this.onchangeReceiver}
                            aria-describedby="receiver-helper-text"                              
                        />
                        <FormHelperText id="receiver-helper-text"></FormHelperText>
                    </FormControl>
                    
                    <FormControl disabled={disabled} style={{width: "100%"}} error={error}>
                        <InputLabel className="MuiInputLabel-shrink MuiFormLabel-filled" htmlFor="receiver_address">Адрес получателя</InputLabel>
                        <MyReactDadata 
                            disabled={disabled}                            
                            id="receiver_address"
                            token = {common.dadataAPIKey()}
                            query = {this.state.order.receiver_address}
                            placeholder = "Введите адрес города улица, используйте подсказки"
                            onChange = {this.onchangeReceiverAddress}
                        />                            
                        <FormHelperText id="receiver_address-helper-text"></FormHelperText>
                    </FormControl>                                                           
                   
                    <FormControl disabled={disabled} style={{width: "100%"}} error={error}>
                        <InputLabel htmlFor="receiver_telephone">Телефон</InputLabel>
                        <Input
                            id="receiver_telephone"
                            style={{width: "100%"}}
                            type='text'
                            value={order.receiver_telephone}
                            onChange={this.onchangeReceiverTelephone}
                            aria-describedby="receiver_telephone-helper-text"                              
                        />
                        <FormHelperText id="receiver_telephone-helper-text"></FormHelperText>
                    </FormControl>                   


                </Paper>     
                
                </div>

                <Paper style={{margin: "10px", padding: "20px", lineHeight: "1.7", minWidth: "400px"}}>
                    <div style={{fontWeight: "normal", fontSize: "100%", color: "#228ec6", marginBottom: "10px"}}>Отправление</div> 

                    
                    <table 
                        style={{width: "100%"}}
                    >
                        <thead>
                            <tr key="0">
                                <td key="0" style={{color: "#228ec6", fontWeight: "bold", borderTop: "1px solid #228ec6", borderBottom: "1px solid #228ec6"}}>Номер</td>
                                <td key="1" style={{color: "#228ec6", fontWeight: "bold", borderTop: "1px solid #228ec6", borderBottom: "1px solid #228ec6"}}>Наименование</td>
                                <td key="2" style={{color: "#228ec6", fontWeight: "bold", borderTop: "1px solid #228ec6", borderBottom: "1px solid #228ec6"}}>Тип и вес</td>
                                <td key="3" style={{color: "#228ec6", fontWeight: "bold", borderTop: "1px solid #228ec6", borderBottom: "1px solid #228ec6"}}>Наложенный платеж, за ед.</td>
                                <td key="4" style={{color: "#228ec6", fontWeight: "bold", borderTop: "1px solid #228ec6", borderBottom: "1px solid #228ec6"}}>Страховая сумма, за ед.</td>
                                <td key="5" style={{color: "#228ec6", fontWeight: "bold", borderTop: "1px solid #228ec6", borderBottom: "1px solid #228ec6"}}>Количество</td>
                            </tr>
                        </thead>
                        <tbody>
                        {                            
                            this.state.order.lines.map((item, index) => {
                                if (item.number==this.state.current_line) {
                                    return (
                                    /* number,description,cargo_type,cargo_length,cargo_width,cargo_height,cargo_volumetric_weight,cargo_weight,cod,cod_amount,insurable_value, quantity */
                                    <tr key={index}>
                                        <td key="0" colSpan="5" style={{borderTop: "1px solid #228ec6", borderBottom: "1px solid #228ec6", padding: "20px", marginTop: "15px", marginBottom: "25px"}}>
                                        <div>
                                        <FormControl disabled={disabled} style={{width: "350px"}} error={error}>
                                            <InputLabel htmlFor="cargo_description">Наименование ТМЦ</InputLabel>
                                            <Input
                                                id="cargo_description"
                                                style={{width: "100%"}}
                                                type='text'
                                                value={item.description}
                                                onChange={this.setValueAtCurrentLine(item.number, "description")}
                                                aria-describedby="cargo_description-helper-text"                              
                                            />
                                            <FormHelperText id="cargo_description-helper-text"></FormHelperText>
                                        </FormControl> 
                                        </div>
                                        <div>
                                        <FormControl disabled={disabled} style={{width:"250px", marginRight: "15px"}}>
                                            <InputLabel htmlFor="cargoType">Тип отправления</InputLabel>
                                            <Select
                                                style={{width:"100%"}}
                                                onChange={this.setValueAtCurrentLine(item.number, "cargo_type")}
                                                value={item.cargo_type}
                                                name="cargoType"
                                                inputProps={{
                                                    id: 'cargoType',
                                                }}
                                            > 
                                            <MenuItem key={"Письмо"} value={"Письмо"}>{"Письмо"}</MenuItem>
                                            <MenuItem key={"Посылка"} value={"Посылка"}>{"Посылка"}</MenuItem>                                                                                                                                       
                                            </Select>
                                            <FormHelperText></FormHelperText>
                                        </FormControl>
                                        
                                        { item.cargo_type === "Посылка" &&                            
                                            <span>
                                            <FormControl disabled={disabled} style={{width: "100px", marginRight: "15px"}}>
                                                <InputLabel htmlFor="cargo_length">Длина</InputLabel>
                                                <Input
                                                    id="cargo_length"
                                                    style={{width: "100%"}}
                                                    type='text'
                                                    value={item.cargo_length}
                                                    onChange={this.setValueAtCurrentLine(item.number, "cargo_length")}
                                                    aria-describedby="cargo_length-helper-text" 
                                                    endAdornment={<InputAdornment position="end">см.</InputAdornment>}                                                                                         
                                                />
                                                <FormHelperText id="cargo_length-helper-text"></FormHelperText>
                                            </FormControl>                        
                                    
                                            <FormControl disabled={disabled} style={{width: "100px", marginRight: "15px"}}>
                                                <InputLabel htmlFor="cargo_width">Ширина</InputLabel>
                                                <Input
                                                    id="cargo_width"
                                                    style={{width: "100%"}}
                                                    type='text'
                                                    value={item.cargo_width}
                                                    onChange={this.setValueAtCurrentLine(item.number, "cargo_width")}
                                                    aria-describedby="cargo_width-helper-text"
                                                    endAdornment={<InputAdornment position="end">см.</InputAdornment>}                                                                                          
                                                />
                                                <FormHelperText id="cargo_width-helper-text"></FormHelperText>
                                            </FormControl> 

                                            <FormControl disabled={disabled} style={{width: "100px", marginRight: "15px"}}>
                                                <InputLabel htmlFor="cargo_height">Высота</InputLabel>
                                                <Input
                                                    id="cargo_height"
                                                    style={{width: "100%"}}
                                                    type='text'
                                                    value={item.cargo_height}
                                                    onChange={this.setValueAtCurrentLine(item.number, "cargo_height")}
                                                    aria-describedby="cargo_height-helper-text"
                                                    endAdornment={<InputAdornment position="end">см.</InputAdornment>}                                                            
                                                />
                                                <FormHelperText id="cargo_height-helper-text"></FormHelperText>
                                            </FormControl> 

                                            <FormControl disabled={disabled} style={{width: "150px", marginRight: "15px"}}>
                                                <InputLabel htmlFor="cargo_volumetric_weight">Удельный вес ед.</InputLabel>
                                                <Input
                                                    disabled
                                                    id="cargo_volumetric_weight"
                                                    style={{width: "100%"}}
                                                    type='text'
                                                    value={item.cargo_volumetric_weight}
                                                    aria-describedby="cargo_volumetric_weight-helper-text"
                                                    endAdornment={<InputAdornment position="end">кг.</InputAdornment>}                              
                                                />
                                                <FormHelperText id="cargo_volumetric_weight-helper-text"></FormHelperText>
                                            </FormControl>
                                            </span>                        
                                        }
                                        </div> 

                                        <div>
                                        <FormControl disabled={disabled} style={{width: "200px"}}>
                                            <InputLabel htmlFor="cargo_weight">Вес ед.</InputLabel>
                                            <Input
                                                id="cargo_weight"
                                                style={{width: "100%"}}
                                                type='text'
                                                value={item.cargo_weight}
                                                onChange={this.setValueAtCurrentLine(item.number, "cargo_weight")}
                                                aria-describedby="cargo_weight-helper-text"
                                                endAdornment={<InputAdornment position="end">кг.</InputAdornment>}                                                             
                                            />
                                            <FormHelperText id="cargo_weight-helper-text"></FormHelperText>
                                        </FormControl> 
                                        </div>

                                        <div>
                                        <FormControl disabled={disabled} style={{width: "200px"}}>
                                            <InputLabel htmlFor="cod_amount">Сумма наложенного платежа за ед.</InputLabel>
                                            <Input
                                                id="cod_amount"
                                                style={{width: "100%"}}
                                                type='text'
                                                value={item.cod_amount}
                                                onChange={this.setValueAtCurrentLine(item.number, "cod_amount")}
                                                aria-describedby="cod_amount-helper-text"
                                                endAdornment={<InputAdornment position="end">руб.</InputAdornment>}                                                             
                                            />
                                            <FormHelperText id="cod_amount-helper-text"></FormHelperText>
                                        </FormControl> 
                                        </div>

                                        <div>
                                        <FormControl disabled={disabled} style={{width: "200px"}}>
                                            <InputLabel htmlFor="insurable_value">Сумма страхования груза за ед.</InputLabel>
                                            <Input
                                                id="insurable_value"
                                                style={{width: "100%"}}
                                                type='text'
                                                value={item.insurable_value}
                                                onChange={this.setValueAtCurrentLine(item.number, "insurable_value")}
                                                aria-describedby="insurable_value-helper-text"
                                                endAdornment={<InputAdornment position="end">руб.</InputAdornment>}                                                             
                                            />
                                            <FormHelperText id="cod_amount-helper-text"></FormHelperText>
                                        </FormControl> 
                                        </div>

                                        <div>
                                        <FormControl disabled={disabled} style={{width: "200px"}}>
                                            <InputLabel htmlFor="quantity">Количество</InputLabel>
                                            <Input
                                                id="quantity"
                                                style={{width: "100%"}}
                                                type='text'
                                                value={item.quantity}
                                                onChange={this.setValueAtCurrentLine(item.number, "quantity")}
                                                aria-describedby="quantity-helper-text"
                                                endAdornment={<InputAdornment position="end">шт.</InputAdornment>}                                                             
                                            />
                                            <FormHelperText id="quantity-helper-text"></FormHelperText>
                                        </FormControl> 
                                        </div>

                                        <Button                                            
                                            onClick={this.pressOkAtCargoList}
                                        >
                                            Ок
                                        </Button>
                                        <Button
                                            disabled={disabled}
                                            onClick={this.pressDeleteAtCargoList(item.number)}
                                        >
                                            Удалить
                                        </Button>
                                        </td>
                                    </tr>
                                    )
                                } else {
                                    let typeText = item.cargo_type ;
                                    if (item.cargo_type == "Посылка") {
                                        if (item.cargo_width==0 || item.cargo_length==0 || item.cargo_height == 0) {
                                            typeText = typeText + " габариты не указаны" ;    
                                        }else {
                                            typeText = typeText + " " + item.cargo_length + "x" + item.cargo_width + "x" + item.cargo_height + ", уд. вес " + item.cargo_volumetric_weight ; 
                                        }
                                    }
                                    typeText = typeText + ", вес " + item.cargo_weight ;                                    
                                    
                                    return (                                    
                                        <tr key={index}>
                                            <td>                                                
                                                <Button
                                                    onClick={this.setCurrentLine(item.number)}
                                                >
                                                    {item.number} 
                                                    <b style={{fontSize:"120%", marginLeft: "10px", color: "rgb(34, 142, 198)"}}> ... </b>
                                                </Button>                                            
                                            </td>
                                            <td style={{fontSize:"80%", paddingRight:"10px"}}>{item.description}</td>
                                            <td style={{fontSize:"80%", paddingRight:"10px"}}>{typeText}</td>
                                            <td style={{fontSize:"100%", paddingRight:"10px"}}>{item.cod_amount}</td>
                                            <td style={{fontSize:"100%", paddingRight:"10px"}}>{item.insurable_value}</td>
                                            <td style={{fontSize:"100%", paddingRight:"10px"}}>{item.quantity}</td>
                                        </tr>
                                    )    
                                }
                            })                            
                        }
                        </tbody>    
                    </table>  

                    <Button
                        disabled={disabled}
                        style={{marginTop: "20px", color:"#228ec6", backgroundColor: "rgb(255,255,255)", paddingLeft: "10px", paddingRight: "10px", paddingTop: "6px", paddingBottom: "6px", border: "1px solid #228ec6"}}
                        onClick={this.addCargoItem}
                    >
                        Добавить
                    </Button>                
                                        
                    <div style={{display: "flex", flexWrap: "wrap", alignItem: "stretch", alignContent: "stretch", marginTop: "20px" }}>
                        <div style={{flexGrow: "1"}} >
                            <div>Итого вес: {order.cargo_weight} кг.</div>                           
                            <div>Итого количество мест: {order.amount_of_places}</div>
                            {order.insurable_value!=0 &&
                                <div>Итого объявленная стоимость: {order.insurable_value} руб.</div>    
                            }
                            {order.cod!=0 &&
                                <div>Итого наложенный платеж: {order.cod_amount} руб.</div>    
                            }                            
                        </div>                                             

                        <div style={{flexGrow: "1"}}>
                            <FormControl disabled={disabled} style={{minWidth:"200px", width: "100%", top: "-8px"}} error={false}>
                                <InputLabel htmlFor="add_details">Дополнительная информация</InputLabel>
                                <Input
                                    id="add_details"
                                    style={{width: "100%"}}
                                    type='text'
                                    value={order.add_details}
                                    onChange={this.onchangeAddDetails}
                                    aria-describedby="add_details-helper-text"
                                    multiline
                                    rowsMax="5"
                                    rows="5"
                                />
                                <FormHelperText id="add_details-helper-text"></FormHelperText>
                            </FormControl>
                        </div>
                    </div>                                      
                </Paper>

                <Paper style={{margin: "10px", padding: "20px", lineHeight: "1.7", minWidth: "400px"}}>
                    <div style={{fontWeight: "normal", fontSize: "100%", color: "#228ec6", marginBottom: "10px"}}>Стоимость и оплата отправления</div> 

                    <div>
                    <FormControl disabled={disabled} style={{width:"580px"}}>
                        <InputLabel htmlFor="tariff">Тариф</InputLabel>
                        <Select
                            style={{width:"100%"}}
                            onChange={this.onchangeTariff}
                            onFocus={this.LoadTariff}
                            value={this.state.tariff}
                            name="tariff"
                            inputProps={{
                                id: 'tariff',
                            }}
                        >
                        {this.state.tariffList.length==0 && 
                                <MenuItem key="0" value="0">
                                    Тариф не найден
                                </MenuItem>
                        }
                        {this.state.tariffList.length>0 &&                                
                            this.state.tariffList.map((item, index) => {
                                return (
                                    <MenuItem key={index} value={item.id}>
                                        {item.description}
                                    </MenuItem>
                                )
                            })                            
                        }                        
                        </Select>
                        <FormHelperText></FormHelperText>
                    </FormControl>
                    </div>
                    
                    <div style={{ borderLeft: "2px solid #228ec6", paddingLeft: "10px"}}>
                        <pre style={{fontFamily: "inherit"}}>{order.tariff_detail}</pre>
                    </div>                   
                    
                    <div>
                    <FormControl disabled={disabled} style={{width: "150px", marginRight: "15px"}} error={false}>
                        <InputLabel htmlFor="amount">Стоимость</InputLabel>
                        <Input
                            disabled
                            id="amount"
                            style={{width: "150px"}}
                            type='number'
                            value={order.amount}                            
                            aria-describedby="amount-helper-text"
                            endAdornment={<InputAdornment position="end">руб.</InputAdornment>}                                                             
                        />
                        <FormHelperText id="amount-helper-text"></FormHelperText>
                    </FormControl> 
                    
                    <FormControl disabled={disabled_pp} style={{width:"200px", marginRight: "15px"}}>
                        <InputLabel htmlFor="paid_type">Тип оплаты</InputLabel>
                        <Select
                            style={{width:"100%"}}
                            onChange={this.onchangePaidType}
                            value={order.paid_type}
                            name="paidTypeSelect"
                            inputProps={{
                                id: 'paidTypeSelect',
                            }}
                        > 
                        { order.paid_type === "" && 
                            <MenuItem  key="empty" value="0">{'\u00A0'}</MenuItem >                        
                        }                                               
                        { order.sender_contract_number !== "" && 
                            <MenuItem key={"2"} value={"2"}>По договору</MenuItem>
                        } 
                        <MenuItem key={"3"} value={"3"}>Наличные</MenuItem>
                        <MenuItem key={"4"} value={"4"}>Онлайн на сайте</MenuItem>
                        </Select>
                        <FormHelperText></FormHelperText>
                    </FormControl>

                    { order.paid_type === "4" &&
                        <FormControl style={{width:"200px"}}>
                            <Button 
                                disabled={disabled} 
                                style={{color:"rgba(0,0,0,0.8)", backgroundColor: disabled?"grey":"rgb(255,255,255)", width: "180px", paddingTop: "10px", paddingBottom: "10px", border: "1px solid grey"}}
                                onClick={this.onlinePay}>
                                <img src={creditCard} alt="" style={{width: "30px", height: "30px", marginRight: "10px"}}/>
                                ОПЛАТИТЬ 
                            </Button>
                        </FormControl>
                    }

                    <FormControl disabled={disabled_pp} style={{width:"200px"}}>
                        <InputLabel htmlFor="payer">Плательщик</InputLabel>
                        <Select
                            style={{width:"100%"}}
                            onChange={this.onchangePayer}
                            value={payer}
                            name="payerSelect"
                            inputProps={{
                                id: 'payerSelect',
                            }}
                        > 
                        <MenuItem key="0" value="0">За счет отправителя</MenuItem >                        
                        <MenuItem key="1" value="1">За счет получателя</MenuItem>
                        
                        </Select>
                        <FormHelperText></FormHelperText>
                    </FormControl>
                    </div>

                    
                </Paper>                
                </div>  

                <div>
                <Paper style={{margin: "10px", padding: "20px", lineHeight: "1.7", minWidth: "400px"}}>    
                    <Button disabled={disabled}
                        style={{color:"#ffffff", backgroundColor: disabled?"grey":"#228ec6", width: "220px", paddingTop: "14px", paddingBottom: "14px", marginRight: "20px"}}
                        onClick={this.save}
                    >
                        Сохранить заказ
                    </Button>
                    <Button disabled={disabled}
                        style={{color:"#ffffff", backgroundColor: disabled?"grey":"#228ec6", width: "220px", paddingTop: "14px", paddingBottom: "14px"}}
                        onClick={this.print}
                    >
                        Печать накладной
                    </Button>
                </Paper>    
                </div>             

            </>
        )
    }
}


export default Order;


