import React, {Component} from 'react';
import { Redirect } from 'react-router-dom' ;
import common from './Common.js' ;

class Logout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            success: false
        }
    }
    
    componentDidMount() {
        let session = common.sessionUuid() ;
        let baseURL = common.baseURL() ;   
        let xhr = new XMLHttpRequest();
        let url = baseURL + "?func=Logout&session=" + session ;
        xhr.open('GET', url, true);
        xhr.send() ;
        xhr.onreadystatechange = () => { 
            if (xhr.readyState !== 4) return;
            if (xhr.status === 200) {
                let r = JSON.parse(xhr.responseText) ;               
                if (r.success) {
                    this.setState({
                        success: true
                    }) ;    
                }                  
            }else {
                this.setState({
                    error: true                        
                }) ;
                console.log(xhr.status) ;    
                console.log(xhr.statusText) ;    
            }
        }
    } 
    
    render() {
        return (
            <>
                {
                    this.state.success &&
                    <Redirect to='/'/>
                }
            
            </>
        )
    }

}

export default Logout ;