import React, {Component} from 'react';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import {Button} from '@material-ui/core';
import common from './Common.js' ;

class LoginForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            login: '',
            password: '',
            showPassword: false,
            error: false
        } ;
        // binding methods
        // this.<name> = this.<name>.bind(this) ;
        this.onchangeLogin = this.onchangeLogin.bind(this) ;
        this.onchangePassword = this.onchangePassword.bind(this) ;
        this.onclickShowPassword = this.onclickShowPassword.bind(this) ;
        this.onMouseDownPassword = this.onMouseDownPassword.bind(this) ;
        this.onClickButton = this.onClickButton.bind(this) ;
    }

    onchangeLogin(e) {
        this.setState({
            login: e.target.value,
            error: false            
        }) ;
    }

    onchangePassword(e) {
        this.setState({
            password: e.target.value,
            error: false
        }) ;
    }

    onclickShowPassword(e) {
        let showPassword = this.state.showPassword ;
        this.setState({showPassword: !showPassword}) ;
    }

    onMouseDownPassword(e) {
        e.preventDefault();
    }

    onClickButton() {
        let baseURL = common.baseURL() ;   
        let xhr = new XMLHttpRequest();
        let url = baseURL + "?func=UserLogin&login=" + this.state.login + "&password=" + this.state.password ;
        xhr.open('GET', url, true);
        xhr.send() ;
        xhr.onreadystatechange = () => { 
            if (xhr.readyState !== 4) return;
            if (xhr.status === 200) {
                let r = JSON.parse(xhr.responseText) ;               
                if (r.success) {
                    this.setState({
                        error: false                     
                    }) ;
                    this.props.success(r.data.session) ; 
                }else {
                    this.setState({
                        error: true                        
                    }) ;
                }                  
            }else {
                this.setState({
                    error: true                        
                }) ;
                /* 
                console.log(xhr.status) ;    
                console.log(xhr.statusText) ;    
                */
            }
        }       
    }

    render() {
        let errorMessage = this.state.error?"Не корректные данные авторизации":"" ;
        return (
            <>
                <div style={{margin: "auto", width: "300px", marginTop: "60px"}}>
                    <Paper>
                        <div style={{padding: "20px"}}>
                            <div style={{fontSize: "130%", marginBottom: "20px"}}>Вход</div>
                            
                            <FormControl style={{marginBottom: "20px", width: "260px"}} error={this.state.error}>
                            <InputLabel htmlFor="input-login" style={{color: "#228ec6"}}>Электронная почта</InputLabel>
                            <Input
                                id="input-login"
                                type='text'
                                value={this.state.login}
                                onChange={this.onchangeLogin}
                                aria-describedby="input-login-helper-text"                              
                            />
                            <FormHelperText id="input-login-helper-text">{errorMessage}</FormHelperText>
                            </FormControl>
                              
                            
                            <FormControl style={{marginBottom: "20px", width: "260px"}} error={this.state.error}>
                            <InputLabel htmlFor="input-password" style={{color: "#228ec6"}}>Пароль</InputLabel>
                            <Input
                                id="input-password"
                                type={this.state.showPassword ? 'text' : 'password'}
                                value={this.state.password}
                                onChange={this.onchangePassword}
                                endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={this.onclickShowPassword} 
                                    onMouseDown={this.onMouseDownPassword}
                                    >
                                    {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                                }
                            />
                            </FormControl> 

                            <FormControl style={{marginBottom: "20px", width: "260px"}}>
                                <Button style={{backgroundColor: "#228ec6", color: "#ffffff"}}
                                    onClick={this.onClickButton}
                                    >Войти</Button>
                            </FormControl>

                            <FormControl style={{marginBottom: "20px", width: "260px", fontSize: "80%"}}>
                                Если Вы забыли свой логин или пароль свяжитесь с Вашим менеджером по телефонам:
                                <br/><br/>      
                                <a style={{color: "#228ec6", display: "inline"}} href="tel:+78123132472">+7(812)-313-24-72</a><br/>         
                                <a style={{color: "#228ec6", display: "inline"}} href="tel:+78127482808">+7(812)-748-28-08</a> 
                            </FormControl>            


                        </div>
                    </Paper>                    
                </div>                    
            </>
        )

    }

}

export default LoginForm ;