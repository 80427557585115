import React, {Component} from 'react';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import FaceIcon from '@material-ui/icons/Face';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import DateFnsUtils from '@date-io/date-fns';
import ruLocale from "date-fns/locale/ru";

import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
  } from '@material-ui/pickers';
import {Link} from 'react-router-dom' ;
import common from './Common.js' ;

class Orders extends Component {

    constructor(props) {
        super(props);
        this.state = {
            search: "",
            dateFrom: new Date(),
            dateTo: new Date(),
            words: [],
            orders: [],
            renderForm: 0,
            renderCount: 100
        } ;
                
        // TEST SCROLLER
        /*
        for(let i=0; i<1000; i++) {
            let order = {} ;
            Object.assign(order, orders[0]) ;
            orders.push(order) ;
        }

        this.state = {
            orders: orders,
            renderForm: 0,
            renderCount: 100
        }
        */ 

        // binding methods
        // this.<name> = this.<name>.bind(this) ;
        this.onchangeSearch = this.onchangeSearch.bind(this) ;
        this.searchOrders = this.searchOrders.bind(this) ;
        this.ondeleteWord = this.ondeleteWord.bind(this) ;
        this.ondeleteWordByKey = this.ondeleteWordByKey.bind(this) ;
        this.loadOrders = this.loadOrders.bind(this) ;
        this.changeDateTo = this.changeDateTo.bind(this) ;
        this.changeDateFrom = this.changeDateFrom.bind(this) ;
    }

    onchangeSearch(event) {
        let value = event.target.value ;
        this.setState({
            search: value
        }) ;    
    }

    searchOrders() {
        let inputWords = this.state.search.split(" ") ;
        inputWords = inputWords.filter((word) => word !== " ") ;
        inputWords = inputWords.filter((word) => word !== "") ;
        let words = this.state.words ;
        inputWords.forEach(element => {
            words.push(element) ;    
        });
        this.setState({
            search: "",
            words: words,    
        }) ;
        setTimeout(()=>{this.loadOrders()}, 200) ; 
    }

    ondeleteWord(key) {
        let deleteWord = this.state.words[key-1] ;
        let words = this.state.words.filter((word) => word !== deleteWord) ;
        this.setState({
            words: words               
        }) ;
        setTimeout(()=>{this.loadOrders()}, 200) ; 
    }

    ondeleteWordByKey(Key) {
        let keyByChip = Key ;
        return () => {
            this.ondeleteWord(keyByChip) ;
        } ;                
    }

    changeDateTo(date) {
        if (date==null || !isNaN(date.getDate())) {
            this.setState({
                dateTo: date
            }) ;
            setTimeout(()=>{this.loadOrders()}, 200) ;
        } ;        
    }

    changeDateFrom(date) {
        if (date==null || !isNaN(date.getDate())) {
            this.setState({
               dateFrom: date
            }) ;
            setTimeout(()=>{this.loadOrders()}, 200) ;
        }    
    }

    loadOrders() {
        let DateFns = new DateFnsUtils(0) ;
        let session = common.sessionUuid() ; 
        let baseURL = common.baseURL() ;       
        let xhr = new XMLHttpRequest();
        let url = baseURL + "?func=GetOrders&session=" + session ;
        if (this.state.dateTo != null) {
            url += "&date_to=" + DateFns.format(this.state.dateTo,"yyyy-MM-dd") ;
        }
        if (this.state.dateFrom != null) {
            url += "&date_from=" + DateFns.format(this.state.dateFrom,"yyyy-MM-dd") ;
        }
        if (this.state.words.length > 0) {
            let ss = this.state.words.join("|") ;
            url += "&search=" + ss ; 
        } ;
        if (this.props.forHome==="true") {
            url += "&forHome=true" ;
        } ;
        xhr.open('GET', url, true);
        xhr.send() ;
        xhr.onreadystatechange = () => { 
            if (xhr.readyState !== 4) return;
            if (xhr.status === 200) {
                let r = JSON.parse(xhr.responseText) ;               
                if (r.success) {
                    this.setState({
                        orders: r.data
                    }) ;    
                }                  
            }else {
                this.setState({
                    orders: []                        
                }) ;
                // console.log(xhr.status) ;    
                // console.log(xhr.statusText) ;    
            }
        }
    }


    componentDidMount() {               
        this.loadOrders() ;
    }    
    

    render() {
        let evenLine = true ;
        let evenLineStyle = {backgroundColor:"rgba(34, 142, 198, 0.2)"} ;
        let noEvenLineStyle = {backgroundColor:"#ffffff"} ; 
        let orders = [] ;
        let renderFrom = this.state.renderForm  ;
        let renderTo = Math.min(renderFrom + this.state.renderCount, this.state.orders.length - renderFrom) ;
        let i = 0 ; 
        let tableHeight = "calc(100vh - 260px)" ;
        if (this.props.forHome==="true") {
            tableHeight = "auto" ;   
        }

        for(let i=renderFrom; i<renderTo; i++) {
            orders.push(this.state.orders[i]) ;             
        }
        
        return (
            <Paper style={{padding: "20px", userSelect:"none"}}>
                { this.props.forHome!=="true" &&
                    <div className="App-speedDialWraper">
                        <Button style={{color:"#ffffff", backgroundColor: "#228ec6", borderRadius: "50%", width: "60px", height: "60px", paddingTop: "14px"}}><Link to={'/order/new'}><SpeedDialIcon/></Link></Button>
                    </div>
                }
                { this.props.forHome==="true" &&
                    <>
                    <Button style={{color:"#ffffff", backgroundColor: "#228ec6", marginBottom: "10px", marginRight: "15px"}}><Link style={{textDecoration:"none"}} to={'/order/new'}>НОВЫЙ ЗАКАЗ</Link></Button>  
                    <Button style={{color:"#ffffff", backgroundColor: "#228ec6", marginBottom: "10px"}}><Link style={{textDecoration:"none"}} to={'/orders'}>ВСЕ ЗАКАЗЫ</Link></Button> 
                    </>                 
                }
                { this.props.forHome!=="true" &&   
                    <>                    
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
                            <FormControl style={{marginRight: "20px", width: "140px"}}>                                
                                <KeyboardDatePicker
                                    style={{margin: "0px"}}
                                    disableToolbar
                                    variant="inline"
                                    format="dd.MM.yyyy"
                                    margin="normal"
                                    id="date-from"
                                    label="c"
                                    invalidDateMessage="Ошибка"
                                    value={this.state.dateFrom}
                                    onChange={this.changeDateFrom}
                                    KeyboardButtonProps={{
                                        'aria-label': 'Изменить дату с',
                                    }}
                                />
                            </FormControl>
                            <FormControl style={{marginRight: "20px", width: "140px"}}>
                                <KeyboardDatePicker
                                    style={{margin: "0px"}}
                                    disableToolbar
                                    variant="inline"
                                    format="dd.MM.yyyy"
                                    margin="normal"
                                    id="date-to"
                                    label="по"
                                    invalidDateMessage="Ошибка"
                                    value={this.state.dateTo}
                                    onChange={this.changeDateTo}
                                    KeyboardButtonProps={{
                                        'aria-label': 'Изменить дату по',
                                    }}
                                />
                            </FormControl>
                        </MuiPickersUtilsProvider>
                    
                    <FormControl style={{marginRight: "20px"}}>
                        <InputLabel htmlFor="receiver">Поиск</InputLabel>
                        <Input
                            id="search"
                            style={{width: "240px"}}
                            type='text'
                            value={this.state.search}
                            onChange={this.onchangeSearch}
                            aria-describedby="receiver-helper-text"                              
                        />
                        <FormHelperText id="receiver-helper-text"></FormHelperText>                    
                    </FormControl>
                    <FormControl>
                        <Button 
                            style={{color:"#ffffff", backgroundColor: "#228ec6", width: "120px", paddingTop: "10px", paddingBottom: "10px"}}
                            onClick={this.searchOrders}
                        >
                            Найти
                        </Button> 
                    </FormControl>                    
                    <div  style={{display: 'flex', justifyContent: 'left',  flexWrap: 'wrap',  marginBottom: "15px"}}>                
                        {                 
                            this.state.words.map((item)=>{
                                return (
                                    <Chip key={++i} label={item} onDelete={this.ondeleteWordByKey(i)} color="primary" style={{marginRight: "15px", backgroundColor: "#228ec6"}}/>                                                               
                                )                            
                            })
                        }                    
                    </div>
                </>
                } 
                <div style={{overflowY: "auto", overflowX: "auto", padding: "0px", margin: "0px", width: "100%", height: tableHeight}}>
                    <table style={{minWidth:"600px", width: "100%"}}>
                        <thead>
                        <tr>
                            <td className="App-tableCaption">Статус</td>
                            <td className="App-tableCaption">Дата</td>
                            <td className="App-tableCaption">Номер заказа</td>
                            <td className="App-tableCaption">Номер заказа отправителя</td>
                            <td className="App-tableCaption">Накладная</td>
                            <td className="App-tableCaption">Получатель</td>
                            <td className="App-tableCaption">Куда</td>
                            <td className="App-tableCaption">Вес</td>
                            <td className="App-tableCaption">Стоимость</td>
                        </tr> 
                        </thead>
                        <tbody>
                        {                        
                            orders.map((item)=>{
                                evenLine = !evenLine ;  
                                return (
                                <tr key={item.guid} style={evenLine? evenLineStyle : noEvenLineStyle} >
                                    <td className={"App-tableCell al"}>{item.status}</td>
                                    <td className={"App-tableCell al"}>{item.date.slice(0,10)}</td>
                                    <td className={"App-tableCell al"}><Link to={'/order/'+item.guid}>{item.number}</Link></td>  
                                    <td className={"App-tableCell al"}>{item.sender_number}</td>                              
                                    <td className={"App-tableCell al"}>{item.waybill_number}</td>                                 
                                    <td className={"App-tableCell al"}>{item.receiver}</td>
                                    <td className={"App-tableCell al"}>{item.receiver_address}</td>
                                    <td className={"App-tableCell al"}>{item.cargo_weight}</td>
                                    <td className={"App-tableCell ar"}>{item.amount}</td>
                                </tr>  
                                )       
                            })                        
                        }
                        </tbody>
                    </table>
                </div>                     
            </Paper>
              
        )

    }

}

export default Orders;