import React, {Component} from 'react';
import {AppBar, Toolbar, Button} from '@material-ui/core';
import LogoPng from './logo.png';
import {Link} from 'react-router-dom' ;

class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {    
        } ;
        // binding methods
        // this.<name> = this.<name>.bind(this) ;
    }   

    render() {
       
        return (
            <>
                <AppBar position="static" style={{backgroundColor:"#ffffff"}}>
                    <Toolbar>                   
                        <div style={{display: "flex", justifyContent: "space-between", flexWrap: "wrap", alignContent: "stretch", alignItems: "stretch", width: "100%"}}>
                            <div style={{paddingTop: "10px"}}>
                                <img src={LogoPng} style={{width:"180px"}} alt="Logo"/>
                            </div>
                            {this.props.showMenu &&
                                <div style={{minHeight: "52px", paddingTop: "12px", width:"350px"}}>        
                                    <Button color="inherit" style={{color:"#202020"}}><Link to={'/'}>Главная</Link></Button>
                                    <Button color="inherit" style={{color:"#202020"}}><Link to={'/orders'}>Заказы</Link></Button>
                                    <Button color="inherit" style={{color:"#202020"}}><Link to={'/message'}>Обращение</Link></Button>                    
                                    <Button color="inherit" style={{color:"#202020"}}><Link to={'/logout'}>Выход</Link></Button>
                                </div>
                            }    
                        </div>     
                    </Toolbar>                                
                </AppBar>
            </>
        )

    }

}

export default Header;