import React, {Component} from 'react';
import Paper from '@material-ui/core/Paper';

class APIDocs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            functions: [
                {
                    name: "UserLogin",   
                    demo: "http://lk.baltcourier.ru/api.php?func=UserLogin&login=ваш_логин&password=ваш_пароль",
                    result: "{ result: true, data: {session: 'guid сессии'}}",
                    descrioption: ["Получение нового GUID сессии, возвращает JSON c GUID новой сессии."]
                },
                {
                    name: "CheckSessionUid",   
                    demo: "http://lk.baltcourier.ru/api.php?func=CheckSessionUid&session=guid_сессии",
                    result: "{ result: true, data: {session: true}}",
                    descrioption: ["Проверяет активность сессии"]
                },	
                {
                    name: "Logout",   
                    demo: "http://lk.baltcourier.ru/api.php?func=Logout&session=guid_сессии",
                    result: "{ result: true, data: {session: false}}",
                    descrioption: ["Закрывает активную сессии"]
                },
                {
                    name: "GetAccounts",   
                    demo: "http://lk.baltcourier.ru/api.php?func=GetAccounts&session=guid_сессии",
                    result: "{ result: true, data: {данные_ваших_юр_лиц}}",
                    descrioption: ["Получение массива Ваших юр. лиц и адресов отправителей"]
                },
                {
                    name: "GetOrders",   
                    demo: "http://lk.baltcourier.ru/api.php?func=GetOrders&session=guid_сессии",
                    result: "{ result: true, data: [массив_последняя_1000_заказов]}",
                    descrioption: [
                        "Получение массива Ваших юр. лиц и адресов отправителей",
                        "Заказ описывается следующими полями:",
                        "guid:", 
                        "number: номер заказа",
                        "date: дата заказа",
                        "office: С-Петербург или Москва",
                        "create_dt: дата создания заказа",
                        "status: статус заказа",
                        "sender_guid: guid отправителя из GetAccounts",
                        "sender_address: адрес отправителя из GetAccounts",
                        "sender_telephone: телефон отправителя из GetAccounts",
                        "sender_email: адрес эл. почты отправителя из GetAccounts",
                        "sender_contract_number: номер договора отправителя из GetAccounts",
                        "receiver: Получатель",
                        "receiver_guid: не используется",
                        "receiver_address: адрес получателя",
                        "receiver_settlement_code: код КЛАДР/GeoNames населенного пункта получателя",
                        "receiver_settlement: наименование населенного пункта получателя",
                        "receiver_settlement_in_russia: 1 - признак адреса в России",
                        "receiver_telephone: телефон получателя",
                        "receiver_fio: отправление получил ФИО, только для заказов со статусом Доставлен",
                        "receiver_fio_position: отправление получил должность, только для заказов со статусом Доставлен",
                        "urgent_delivery: срочность отправления, Обычное/Срочное",
                        "cargo_type: тип отправления Письмо/Посылка",
                        "cargo_length: длина посылки в см.",
                        "cargo_width: ширина посылки в см.",
                        "cargo_height: высота посылки в см.",
                        "cargo_volumetric_weight: объемный вес посылки рассчитывается как cargo_length * cargo_width * cargo_height / 5000",
                        "cargo_weight: вес отправления, письма до 1 кг.",
                        "cash: признак наличной оплаты",
                        "amount: сумма заказа",
                        "note: комментарий к заказу",
                        "waybill_number: номер накладной",
                        "base_price: базовая цена по тарифу за 1 кг",
                        "add_1_kg: цена за каждый дополнительный кг.",
                        "is_paid: признак что заказ оплачен, не устанавливается из API",
                        "payer_guid: если guid отправителя, то оплата за счет отправителя, иначе оплата за счет получателя",
                        "receiving_dt: дата и время заказ получет получателем, только для заказов со статусом Доставлен",
                        "pay_sender:  не исплоьзуется",
                        "paid_type: тип оплаты заказа 2- по договору, 3- наличные, 4- онлайн на сайте",  
                        "cod: признак наложенного платежа 1 - есть",
                        "cod_amount: сумма наложенного плтежа",
                        "tariff_detail: Текст с описанием ценообразования",
                        "scheduled_delivery_date: плановая дата выполнения заказа",
                        "client_discont: персональная ссылка из GetAccounts", 
                        "amount_by_price: сумма заказа без скидки",
                        "update_dt: дата последнего изменения заказа",
                        "delivery_time: плановый срок доставки в днях",
                        "add_details: дополнительная информация",
                        "day_off_markup: наценка в % за тариф выходного дня",
                        "quantity_kg_in_base_price: количество кг. в базовом тарифе"
                    
                    ]
                },
                {
                    name: "GetOrder",   
                    demo: "http://lk.baltcourier.ru/api.php?func=GetOrder&session=guid_сессии&order=guid_заказа",
                    result: "{ result: true, data: [0: данные_заказ]}",
                    descrioption: [
                        "Получение одного заказа поля см. в GetOrders",
                        "Также передается lines, массив строк заказа состоящий из:",
                        "guid:",
                        "id: уникальный идентфикатор строки",
                        "number: номер строки",
                        "description: описание ТМЦ",
                        "cargo_type: тип отправления Письмо или Посылка",
                        "cargo_length: габаритные размеры для посылки",
                        "cargo_width:",
                        "cargo_height:",
                        "cargo_volumetric_weight: объемный вес",
                        "cargo_weight: вес отправления",
                        "cod: признак наложенного платежа",
                        "cod_amount: сумма наложенного платежа за единицу ТМЦ",
                        "insurable_value: страховая стоимость ТМЦ за единицу",
                        "quantity: количество"]
                },
                {
                    name: "GetTariffList",   
                    demo: "http://lk.baltcourier.ru/api.php?func=GetTariff&session=guid_сессии&sender=код_отправителя&from=код_кладр_откуда&settlement=код_кладр_куда",
                    result: "{ result: true, data: [массив тарифов]}",
                    descrioption: [
                        "Получение тарифов на доставку из одного населенного пункта в другой",
                        "Возвращает массив с перечнем доступных отправителю тарифов",
                        "Тариф описывается следующими полями:",
                        "from_city_code: код КЛАДР города откуда идет отправление",
                        "code: код КЛАДР или GeoNames города куда",
                        "in_russia: 1/0 достака по России",
                        "delivery_option: вариант доставки груза",
                        "delivery_tariff: тариф",
                        "delivery_time: количество дней на доставку",
                        "base_price: базовая цена в рублях",
                        "quantity_kg_in_base_price: количество кг в базовом тарифе",
                        "day_off_markup: наценка в % за тариф выходного дня",
                        "add_1_kg:  цена за кг., для отправлений более 1кг",
                        "add_1_kg_5: цена за кг., для отправлений более 5кг",
                        "add_1_kg_10: цена за кг., для отправлений более 10кг",
                        "add_1_kg_50: цена за кг., для отправлений более 50кг",
                        "add_1_kg_100: цена за кг., для отправлений более 100кг",
                        "add_1_kg_200: цена за кг., для отправлений более 200кг",
                        "add_1_kg_300: цена за кг., для отправлений более 300кг",
                        "add_1_kg_400: цена за кг., для отправлений более 400кг",
                        "add_1_kg_500: цена за кг., для отправлений более 500кг",
                        "add_1_kg_600: цена за кг., для отправлений более 600кг",
                        "add_1_kg_700: цена за кг., для отправлений более 700кг",
                        "add_1_kg_800: цена за кг., для отправлений более 800кг",
                        "add_1_kg_900: цена за кг., для отправлений более 900кг",
                        "add_1_kg_1000: цена за кг., для отправлений более 1000кг",
                        "discount: возможная дополнительная скидка за кол-во одновременных оправлений",
                        "discount_from_shipment: кол-во отправлений для получения скидки",
                        "update_dt: дата и время установки цены"]		                   
                },
                {
                    name: "addNewAddress",
                    demo: "http://lk.baltcourier.ru/api.php?func=addNewAddress&session=guid_сессии&client=guid_организации",   
                    result: "{ result: true, data: {result: true}}",
                    descrioption: [
                        "Добавляет адрес организации отправителя откуда забирать корреспонденцию",
                        "Данные для добавления передаются в JSON POST запросом.",
                        "Передаваемый JSON должен содержать объект со полями:",
                        "clientGuid: guid организации которой добавляем адрес",
                        "address: текстовое поле, адрес нормплизованный по КЛАДР без лишних запятых",
                        "settlementCode: код КЛАДР населенного пункта отправителя",
                        "settlement: наименование населенного пункта отправителя ",
                        "name: контактное лицо",
                        "telephone: номер телефона",
                        "addInfo: дополнительная информация, произвольный текст",
                        "settlementInRussia: 1 для адресов в России, 0 для зарубежных",
                        "description: представление адреса при выборе в заказе"
                    ]   
                },
                {
                    name: "saveOrder",   
                    demo: "http://lk.baltcourier.ru/api.php?func=saveOrder&session=guid_сессии",
                    result: "{ result: Ок, data: {guid: guid_заказа}}",
                    descrioption: ["Изменяет или создает заказ, метод POST, необходимо передать JSON c параметрами заказ, см. GetOrder"]
                }            
            ]
        } ;
        // binding methods
        // this.<name> = this.<name>.bind(this) ;
    }   

    render() {
        return (
            <Paper style={{marginBottom: "20px", padding: "20px"}}>
                <div style={{fontWeight: "bold", fontSize: "140%", color: "#228ec6"}}>
                    Документация по API http://lk.baltcourier.ru/
                </div>
                <p>Вызовы API осуществляются по http протоколу по адресу http://lk.baltcourier.ru/api.php. Параметры к API передаются как GET и POSТ, детали описаны в каждой функции отдельно.</p> 
                {
                    this.state.functions.map( (item) => {
                        return (
                            <>
                                <div style={{marginTop: "10px", fontWeight: "bold", fontSize: "120%", color: "#228ec6"}}>
                                    {item.name}
                                </div>
                                <div>
                                    <span>Пример: </span>
                                    {item.demo}
                                </div>
                                <div>
                                    <span>Результат: </span>
                                    {item.result}
                                </div>
                                <div>
                                    Описание:
                                </div>
                                <div style={{paddingLeft:"10px",fontSize:"80%"}}>
                                {
                                    item.descrioption.map( (row) => {
                                        return (
                                            <div>{row}</div>
                                        )
                                    })
                                }
                                </div>

                            </>
                        )
                    }) 
                }
            </Paper>
        )
    }
}

export default APIDocs;
