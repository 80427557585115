import React, {Component}  from 'react';
import './App.css';
import { Switch, Route } from 'react-router-dom' ;
import Header from './Header';
import LoginForm from './LoginForm' ;
import Logout from './Logout' ;
import Home from './Home' ;
import Orders from './Orders';
import Order from './Order';
import common from './Common.js' ;
import Message from './Message.js' ;
import APIDocs from './APIDocs.js' ;


class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
        session: '',
        userAutorizedTry: false,
        userAutorized: false
    }
    
    // binding methods
    // this.<name> = this.<name>.bind(this) ;
    this.userAutorization = this.userAutorization.bind(this) ;
    this.checkSession = this.checkSession.bind(this) ;    
  }

  userAutorization(session) {
    this.setState({
      session: session,
      userAutorizedTry: true,
      userAutorized: true 
    }) ;
    document.cookie = encodeURIComponent("session") + '=' + encodeURIComponent(session);
    // this.props.history.push('/empty');
    document.location.href = '/home'; 
    /*
    setTimeout(              
        () => { document.location.href = '/home' } ,
        1000
    ) ;
    */  
  }

  componentDidMount() {
    this.checkSession() ;
    setInterval(()=>{this.checkSession() ;}, 5000) ;
  }  
  
  checkSession() {
    let session = common.sessionUuid() ;
    let baseURL = common.baseURL() ;   
    let xhr = new XMLHttpRequest();
    let url = baseURL + "?func=CheckSessionUid&session=" + session ;
    /* console.log("checkSession query: " + url) ; */
    xhr.open('GET', url, true);
    xhr.send() ;
    xhr.onreadystatechange = () => { 
        if (xhr.readyState !== 4) return;
        if (xhr.status === 200) {
          /* console.log("checkSession request: " + xhr.responseText) ;   */
          try {
            let r = JSON.parse(xhr.responseText) ;               
            if (r.success) {
                this.setState({
                  session: session,
                  userAutorizedTry: true,
                  userAutorized: true                    
                }) ;
                                
            }else {
                this.setState({
                  session: '',
                  userAutorizedTry: true,
                  userAutorized: false                  
                }) ;
            }
          } catch (err) {
            this.setState({
              session: '',
              userAutorizedTry: true,
              userAutorized: false                  
            }) ;
          }                    
        }else {
            this.setState({
              session: '',
              userAutorizedTry: true,
              userAutorized: false                        
            }) ;
            /* console.log(xhr.status) ;   */
            /* console.log(xhr.statusText) ;  */
        }
    }

  }  

  render() {
    return (
      <>
      <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"/>
      <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
      <meta
        name="viewport"
        content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
      />
      <div className="App">
        <Header showMenu={this.state.userAutorized}/>       
        <article className="App-article"> 
          {!this.state.userAutorized && this.state.userAutorizedTry &&
            <LoginForm success = {this.userAutorization} />
          }
          {this.state.userAutorized &&          
            <Switch>
              <Route exact path='/' component={Home}/>
              <Route path='/home' component={Home}/>
              <Route path='/orders' component={Orders}/>
              <Route path='/order/:guid' component={Order}/>
              <Route path='/message' component={Message}/>
              <Route path='/apidocs' component={APIDocs}/>
              <Route path='/logout' component={Logout}/>
            </Switch>
          } 
          <div id="auth" data-auth={this.state.userAutorized}></div> 
        </article>
        { /*
        <footer className="App-footer">
          Copyright (с)2019 Балтийский курьер
        </footer>
        */ }
      </div>
      </>
    )
  }  
}

export default App;
